import React, { useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Text } from 'rebass'
//@ts-ignore
import numeral from 'numeral'
import { isMobile } from 'react-device-detect'

import Card from '../../../components/Card'
// import { BIGNUMBER_FMT } from '../../../constants'
import { getBscscanLink, shortenAddress, shortenTransaction } from '../../../utils'
import { ExternalLink, LinkStyledButton } from '../../../theme'
import { useWeb3React } from '@web3-react/core'
import { LightSeparator } from '../../../components/CSS'
import { SuccessText } from '..'
import { TopGamblers } from '../../../hooks/useTopPlayers'
import { getBalanceNumber } from '../../../utils/formatBalance'
import { useActiveWeb3React } from '../../../hooks'
import Logo from '../../../assets/svg/coin.svg'
import { Collapse } from 'react-bootstrap'
import PleaseConnectWallet from '../../../components/PleaseConnectWallet'

const EarnCard = styled(Card)`
  background-repeat: no-repeat;
  background-position: top right;
  color: ${({ theme }) => theme.colors.text1};
  text-align: center;
  padding: 24px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    &:first-child {
      margin-right: initial;
      margin-bottom: 32px;
    }
  `}
  box-shadow: none;
`

const ExtLink = styled(ExternalLink)`
  font-family: GothamRoundedBook;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.text3};
`
const TransactionsHistoryContainer = styled('div')`
    display: grid;
    text-align: center;
    max-height: 95px;
    overflow-y: scroll;
`
const InlineTextBlock = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`
const Table = styled.table`
  &&& {
    table,
    th,
    td {
      border-collapse: collapse;
    }
    th,
    td,
    tr {
      padding: 5px;
      text-align: left;
    }
    table {
      width: 100%;
    }
    tbody {
      font-size: 14px;
      display: block;
      overflow-y: scroll;
      max-height: 310px;
    }
    thead {
      display: flex;
    }
    thead > tr {
      display: flex;
      flex: 1;
    }
    thead > tr > th {
      flex: 1;
    }
    
    tbody > tr {
      display: flex;
      flex: 1;
    }
    tbody > tr > td {
      flex: 1;
    }
  }
`

const TransactionsHistory = ({ transactions } : {transactions: [string]}) => {
  const { chainId } = useActiveWeb3React()
  const [isOpenedTx, setIsOpenedTx] = useState(false)
  return (
    <td>
      <LinkStyledButton onClick={() => setIsOpenedTx(!isOpenedTx)}>
        See
      </LinkStyledButton>
      <Collapse in={isOpenedTx}>
        <TransactionsHistoryContainer>
          {transactions.map((tx, iTx) => (
            <ExtLink href={chainId ? getBscscanLink(chainId, tx, 'transaction') : ''} key={iTx}>
              {shortenTransaction(tx, isMobile ? 1 : 2)}
            </ExtLink>
          ))}
        </TransactionsHistoryContainer>
      </Collapse>
    </td>
  )
}

const Winners = ({ topPlayers}: { topPlayers: TopGamblers[]}) => {
  const { chainId } = useActiveWeb3React()
  const { account } = useWeb3React()
  const myUser = topPlayers.find(game => game.player === account)
  const myUserIndex = topPlayers.findIndex(game => game.player === account)
  const theme = useContext(ThemeContext)


  return (
    <EarnCard>
      <Text fontSize={24} fontWeight={700} style={{ paddingBottom: 10 }}>
        Top gamblers (last 24h)
      </Text>
      <LightSeparator />
      <Table>
        <thead>
          <tr>
            <th>Position</th>
            <th style={{ paddingRight: 10 }}>Player</th>
            <th style={{ paddingRight: 20 }}>Wager volume</th>
            {!isMobile && (<th>Gain</th>)}
            {!isMobile && (<th>Transactions</th>)}
          </tr>
        </thead>
        <tbody>
        {(!account) && (
          <tr style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '40px 0'
          }}>
            <PleaseConnectWallet/>
          </tr>
        )}
        {myUser && (
          <tr style={{
            borderBottom: '0.5px solid #3c3c3c',
            marginBottom: 30
          }}>
            <td>{myUserIndex + 1}</td>
            <td style={{ paddingRight: 10 }}>
              <ExtLink
                href={chainId ? getBscscanLink(chainId, myUser.player, 'address') : ''}
                style={{
                  width: '100%',
                  color: account === myUser.player ? theme.colors.primary1 : theme.colors.text3
                }}
              >
                {shortenAddress(myUser.player, isMobile ? 1 : 2)}
              </ExtLink>
            </td>
            <td style={{ paddingRight: 20 }}>
              <InlineTextBlock>
                <Text style={{ paddingRight: 10 }}>
                  {numeral(getBalanceNumber(myUser.totalVolumeWager)).format('0[.][00] a')}
                </Text>
                <img src={Logo} style={{ height: '15px', marginTop: 1 }} alt="" />
              </InlineTextBlock>
            </td>
            {!isMobile && (
              <td>
                <InlineTextBlock>
                  <SuccessText style={{ paddingRight: 10 }}>
                    {numeral(getBalanceNumber(myUser.totalGain)).format('0[.][00] a')}
                  </SuccessText>
                  <img src={Logo} style={{ height: '15px', marginTop: 1 }} alt="" />
                </InlineTextBlock>
              </td>
            )}
            {!isMobile && (
              <TransactionsHistory transactions={myUser.transactions}/>
            )}
          </tr>
        )}
          {topPlayers &&
            topPlayers
              .map((winner, i) => {
                const { player, totalVolumeWager, totalGain, transactions } = winner
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td style={{ paddingRight: 10 }}>
                      <ExtLink
                        href={chainId ? getBscscanLink(chainId, player, 'address') : ''}
                        style={{
                          width: '100%',
                          color: account === player ? theme.colors.primary1 : theme.colors.text3
                        }}
                      >
                        {shortenAddress(player, isMobile ? 1 : 2)}
                      </ExtLink>
                    </td>
                    <td style={{ paddingRight: 20 }}>
                      <InlineTextBlock>
                        <Text style={{ paddingRight: 10 }}>
                          {numeral(getBalanceNumber(totalVolumeWager)).format('0[.][00] a')}
                        </Text>
                        <img src={Logo} style={{ height: '15px', marginTop: 1 }} alt="" />
                      </InlineTextBlock>
                    </td>
                    {!isMobile && (
                      <td>
                        <InlineTextBlock>
                          <SuccessText style={{ paddingRight: 10 }}>
                            {numeral(getBalanceNumber(totalGain)).format('0[.][00] a')}
                          </SuccessText>
                          <img src={Logo} style={{ height: '15px', marginTop: 1 }} alt="" />
                        </InlineTextBlock>
                      </td>
                      )}
                    {!isMobile && (
                     <TransactionsHistory transactions={transactions}/>
                    )}
                  </tr>
                )
              })}
        </tbody>
      </Table>
    </EarnCard>
  )
}

export default Winners
