import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import { ExternalLink } from '../../theme'
import { Text } from 'rebass'
import { LUCK_TOKEN } from '../../luck/entities'
import useLuck from '../../hooks/useLuck'
import { isMobile } from 'react-device-detect'

const NavElement = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 65px;
  flex: 1;
  ${({ theme }) => theme.mediaWidth.upToLarge`
      flex-direction: column;
      align-items: flex-start;
  `};
`


const activeClassName = 'ACTIVENAV'
const NavItem = styled(NavLink).attrs({
  activeClassName
})`
  color: ${({ theme }) => theme.colors.text2};
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
  margin-left: 40px;

  &:hover {
    color: inherit;
    text-decoration: unset;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.colors.primary1};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-left: 16px;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
      margin: 8px 0;
      font-size: larger;
  `};
`
const BuyLuckCoins = styled(ExternalLink)`
  color: ${({ theme }) => theme.colors.text2};
  margin: 8px 0;
  padding-left: 0;

  @media screen and (min-width: 640px) {
    margin: 0;
    padding-left: 40px;
    width: 172px;
  }
`
const Badge = styled(Text)`
  font-size: 10px;
  background: white;
  border-radius: 20px;
  padding: 1px 8px;
  position: absolute;
  top: -14px;
  right: -25px;
  color: ${({ theme }) => theme.colors.primary1};
`

export default function Nav({ handleCloseMenu = Function.prototype }: any) {
  const location = useLocation()
  const newPags: String[] = ['/home', '/luckyroll', '/leaderboard', '/faq', '/airdrops']
  const breadMatch = useRouteMatch({
    path: '/staking/:poolId',
    strict: true,
    sensitive: true
  })
  const luck = useLuck()
  const theme = useContext(ThemeContext)
  const luckToken = LUCK_TOKEN[luck?.chainId ?? 97]
  const { address: luckAddress } = luckToken

  return (
    <NavElement>
      <NavItem
        style={{margin: 0}}
        to={'/home'}
        onClick={handleCloseMenu}
        className={location.pathname === '/home' ? activeClassName : ''}
      >
        Home
      </NavItem>
      <NavItem
        to={'/luckyroll'}
        onClick={handleCloseMenu}
        className={!newPags.includes(location.pathname) && !breadMatch ? activeClassName : ''}
      >
        Game
      </NavItem>
      <NavItem
        to={'/leaderboard'}
        onClick={handleCloseMenu}
        className={!newPags.includes(location.pathname) && !breadMatch ? activeClassName : ''}
      >
        Leaderboard
      </NavItem>
      <NavItem
        to={'/airdrops'}
        onClick={handleCloseMenu}
        className={!newPags.includes(location.pathname) && !breadMatch ? activeClassName : ''}
        style={{position: 'relative'}}
      >
        <Badge>NEWS</Badge>
        Airdrops
      </NavItem>
      <NavItem
        to={'/faq'}
        onClick={handleCloseMenu}
        className={!newPags.includes(location.pathname) && !breadMatch ? activeClassName : ''}
      >
        Help Center
      </NavItem>
      <BuyLuckCoins href={`https://bnex.org/#/swap?outputCurrency=${luckAddress}`}>
        <Text fontWeight={500} fontSize={isMobile ? 23 : 16} color={theme.colors.green1}>
          Buy LUCK coins
        </Text>
      </BuyLuckCoins>
    </NavElement>
  )
}
