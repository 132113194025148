import {
  MULTICALL_ADDRESS_LIST,
  MASTER_ADDRESS_LIST,
  LUCK_ADDRESS_LIST,
  MultiCallJson,
  ChainId,
  LuckJson,
  MasterJson
} from './constants'

import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'

export class Contracts {
  multicall: Contract //TODO: Use build-in multicall
  master: Contract
  luck: Contract

  constructor(provider: Web3Provider | JsonRpcSigner, networkId: ChainId) {
    this.multicall = new Contract(MULTICALL_ADDRESS_LIST[networkId], MultiCallJson.abi, provider as any) //TODO: Use build-in multicall
    this.master = new Contract(MASTER_ADDRESS_LIST[networkId], MasterJson.abi, provider as any)
    this.luck = new Contract(LUCK_ADDRESS_LIST[networkId], LuckJson.abi, provider as any)
  }
}
