import styled from 'styled-components'

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.bg2};
`

export const LightSeparator = styled(Separator)`
  opacity: 0.2;
  background-color: ${({ theme }) => theme.colors.text3};
`
