export const burgerMenuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '32px',
    height: '28px',
    left: '36px',
    top: '38px'
  },
  bmBurgerBars: {
    background: '#e3e3e3'
  },
  bmBurgerBarsHover: {
    background: '#a40000'
  },
  bmCrossButton: {
    height: '28px',
    width: '28px'
  },
  bmCross: {
    background: '#a40000'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    backdropFilter: 'blur(4px)',
    background: 'rgb(31 31 31 / 50%)',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#1e1e1e'
  },
  bmItemList: {
    color: '#ffffff',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}
