import React, { useState, ReactElement } from 'react'
import styled from 'styled-components'

import { Collapse } from 'react-bootstrap'
import { Text, Box } from 'rebass'
import { ExternalLink, LinkStyledButton } from '../../theme'
import { Col, Container, Row } from 'react-bootstrap'

import Card from '../../components/Card'
import Frame from '../../components/Frame'

const CollapseLink = styled(LinkStyledButton)`
  color: white;
`

const BnexLink = styled(ExternalLink).attrs({
  href: 'https://bnex.org/#/staking'
})`
  color: ${({ theme }) => theme.colors.green1};
`

const GreenLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.colors.green1};
`

const topics = [
  {
    question: 'What is the address of LuckCoin ?',
    answer: (
      <div>
        Here you go:&nbsp;
        <GreenLink href="https://bscscan.com/address/0x84FfaF0AAE5d23F9953FED188D09fA8a8453D763">
          0x84FfaF0AAE5d23F9953FED188D09fA8a8453D763
        </GreenLink>
      </div>
    )
  },
  {
    question: 'What is “provably fair” on-chain gambling ?',
    answer: (
      <div>
        The house is not and will never influence the result of your bet, this is the primary rule that we have set on
        our protocol so that every bet is fair. Our first audit (made by our partner Bnex) will show you the fairness of
        our protocol.
      </div>
    )
  },
  {
    question: 'What kind of games are we going to play ?',
    answer: (
      <div>
        First, we will launch LuckyRoll, a simple game where you can choose your own probability to win from 1% to 96%
        chances to win, to accordingly get a reward from 99x to 1.03x of your bet. Then we will add new games and events
        for our community. Every loss in our games will result in a burning of the bet’s coins.
      </div>
    )
  },
  {
    question: 'How can I get my LUCK ?',
    answer: (
      <div>
        As a casino, our goal is to focus on the games and events we can provide, that’s why on our platform you won’t
        be able to buy LUCK coins.
        <br />
        <br />
        <b>Luckily</b> we found our first partner, <BnexLink>Bnex</BnexLink> that is handling the liquidity and the
        distribution of our first tokens for us and we will look for more partners to provide LUCK to all the
        communities on BSC. You will need to farm on the pools provided or just buy it.
      </div>
    )
  }
]

const FaqTopic = ({ topic }: { topic: { question: string; answer: ReactElement } }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Box py={3}>
      <CollapseLink onClick={() => setIsOpen(!isOpen)}>{topic.question}</CollapseLink>
      <Collapse in={isOpen}>{topic.answer}</Collapse>
    </Box>
  )
}

export default function Faq() {
  return (
    <Frame maxWidth={800}>
      <Container>
        <Row xs={1} sm={24} md={24} lg={24}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Box py={2}>
              <Card>
                <Box py={3} mx="auto">
                  <Text fontSize={28} fontWeight={500}>
                    LuckCoin, a “provably fair” on-chain gambling Casino, allowing to control your luck.
                  </Text>
                </Box>
                {topics.map((topic, index) => (
                  <FaqTopic key={index} topic={topic} />
                ))}
                <Box py={3} mx="auto">
                  <Text fontSize={22} fontWeight={500}>
                    May the Luck be with you
                    <span role="img" aria-label="wizard-icon">
                      🍀
                    </span>
                  </Text>
                </Box>
              </Card>
            </Box>
          </Col>
        </Row>
      </Container>
    </Frame>
  )
}
