const blockPerSecond = 3

export const getTargetBlockByTimestamp = (actualBlock: number, targetTimestamp: number): number => {
  return actualBlock + (targetTimestamp - Math.floor(Date.now() / 1000)) / blockPerSecond
}

export const getTimestampByTargetBlock = (actualBlock: number, targetBlock: number): number => {
  return Date.now() + (targetBlock - actualBlock) * blockPerSecond * 1000
}

export const zeroPadding = (num: number, digit: number) => {
  var zero = ''
  for (var i = 0; i < digit; i++) {
    zero += '0'
  }
  return (zero + num).slice(-digit)
}

const elapsedTime = () => {
  const since = parseInt(process.env.REACT_APP_DATE_RELEASE_TIMESTAMP ?? '0'),
    elapsed = since - Math.floor(new Date().getTime() / 1000)

  const diff = { days: 0, hours: 0, minutes: 0, seconds: 0 }
  diff.days = Math.floor(elapsed / 86400)
  diff.hours = Math.floor((elapsed / 3600) % 24)
  diff.minutes = Math.floor((elapsed / 60) % 60)
  diff.seconds = Math.floor(elapsed % 60)

  let message = `${diff.days}d ${diff.hours}h ${diff.minutes}m ${diff.seconds}s`
  message = message.replace(/(?:0. )+/, '')
  return message
}

export const blockToTime = () => {
  const since = 1601763145,
    elapsed = since - Math.floor(new Date().getTime() / 1000)

  const diff = { days: 0, hours: 0, minutes: 0, seconds: 0 }
  diff.days = Math.floor(elapsed / 86400)
  diff.hours = Math.floor((elapsed / 3600) % 24)
  diff.minutes = Math.floor((elapsed / 60) % 60)
  diff.seconds = Math.floor(elapsed % 60)

  let message = `${diff.days}d ${diff.hours}h ${diff.minutes}m ${diff.seconds}s`
  message = message.replace(/(?:0. )+/, '')
  return message
}

export const timestampToCountDown = () => {
  return elapsedTime()
}
