import React from 'react'
import styled from 'styled-components'
import { useRouteMatch } from 'react-router-dom'

import { useDarkModeManager } from '../../state/user/hooks'
import Logo from '../../assets/svg/luckcoinred.svg'
import LogoDark from '../../assets/svg/luckcoinred.svg'
// import Wordmark from '../../assets/svg/logo.svg'
// import WordmarkDark from '../../assets/svg/logo-white.svg'

const Wrapper = styled.div`
  text-align: center;
`
const TitleText = styled.div`
  margin: 0 0 28px;
`

export default function BodyHeader({ className }: { className?: any }) {
  const [isDark] = useDarkModeManager()
  const match = useRouteMatch({
    path: '/bakery/:bakeryId',
    strict: true,
    sensitive: true
  })

  return (
    <>
      {!match && (
        <Wrapper className={className}>
          <TitleText>
            <img src={isDark ? LogoDark : Logo} style={{ height: 94 }} alt="logo" />
          </TitleText>
        </Wrapper>
      )}
    </>
  )
}
