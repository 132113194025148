import { BigNumber } from '@ethersproject/bignumber'

import MultiCallJson from '../../constants/abi/Multicall.json'
import MasterJson from '../../constants/abi/LuckMaster.json'
import LuckJson from '../../constants/abi/LuckCoin.json'
import ERC20Json from '../../constants/abi/ERC20.json'
import JSBI from 'jsbi'

export { MultiCallJson, MasterJson, LuckJson, ERC20Json }

export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MAINNET = 56,
  TESTNET = 97
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

type ChainContractList = {
  readonly [chainId in ChainId]: string
}

export const LUCK_ADDRESS_LIST: ChainContractList = {
  [ChainId.MAINNET]: '0x84FfaF0AAE5d23F9953FED188D09fA8a8453D763',
  [ChainId.TESTNET]: '0xAdc17701789FB2422aC0096d9e36FA58B9d6aC4f'
}
export const MASTER_ADDRESS_LIST: ChainContractList = {
  [ChainId.MAINNET]: '0xb44ac5FcF161Ce2883F752650E2e40840e91B7Af',
  [ChainId.TESTNET]: '0xb8CE443eCd4221BD0d4485344de81ADDAF4B7911'
}
export const MULTICALL_ADDRESS_LIST: ChainContractList = {
  [ChainId.MAINNET]: '0x09Bb12a2d5b1E2d3E48Dd446bf11ec1213242adA',
  [ChainId.TESTNET]: '0x02fC928139889a6AA58e0B4f64d72b259359c96E'
}

// exports for internal consumption
export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}

const ONE_MINUTE_IN_SECONDS = BigNumber.from(60)
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS.mul(60)
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS.mul(24)
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS.mul(365)

export const INTEGERS = {
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ZERO: BigNumber.from(0),
  ONE: BigNumber.from(1),
  ONES_31: BigNumber.from('4294967295'), // 2**32-1
  ONES_127: BigNumber.from('340282366920938463463374607431768211455'), // 2**128-1
  ONES_255: BigNumber.from('115792089237316195423570985008687907853269984665640564039457584007913129639935'), // 2**256-1
  INTEREST_RATE_BASE: BigNumber.from('10').pow('18')
}
