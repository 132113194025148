import { useEffect, useState } from 'react'
import { useActiveWeb3React } from './'

const useBlock = () => {
  //TODO: Already managed by redux
  const [block, setBlock] = useState(0)
  const { library } = useActiveWeb3React()

  useEffect(() => {
    if (!library) return
    const interval = setInterval(async () => {
      const latestBlockNumber = await library.getBlockNumber()
      if (block !== latestBlockNumber) {
        setBlock(latestBlockNumber)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [library, block])

  return block
}

export default useBlock
