import React from 'react'
import styled from 'styled-components'

interface PageProps {
  children: React.ReactNode | Element[]
  maxWidth?: number
}

const Frame: React.FC<PageProps> = ({ children, maxWidth }) => {
  return <StyledMain maxWidth={maxWidth}>{children}</StyledMain>
}

const StyledMain = styled.div<PageProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: all 0.4s linear;
  position: relative;
  // max-width: ${props => props.maxWidth ?? '420'}px;
  width: 100%;
`

export default Frame
