import React  from 'react'
import { Text } from 'rebass'

import Card from '../../components/Card'
import useTokenBalance from '../../hooks/useTokenBalance'
import { LUCK_TOKEN } from '../../luck/entities'
import useLuck from '../../hooks/useLuck'
import Value from '../Value'
import Loader from '../Loader'
import { CenterBlock, InlineBlock } from '../../pages/Luckyroll'
import Logo from '../../assets/svg/coin.svg'
import PleaseConnectWallet from '../PleaseConnectWallet'
import { useWeb3React } from '@web3-react/core'
import useLuckPrice from '../../hooks/useLuckPrice'

const UserWalletCard = () => {
  const { account } = useWeb3React()
  const luck = useLuck()
  const luckToken = LUCK_TOKEN[luck?.chainId ?? 97]
  const {symbol } = luckToken
  const luckUserBalance = useTokenBalance(LUCK_TOKEN[luck?.chainId ?? 97])
  const luckPriceInDolls = useLuckPrice()

  return (
    <Card style={{maxWidth: 410}}>
      <CenterBlock>
        <h4>Your wallet</h4>
        {!account && (
          <PleaseConnectWallet/>
        )}
        {luckUserBalance ? (
            <div style={{marginLeft: 10}}>
              <InlineBlock>
                <Value value={luckUserBalance.toBN().toNumber()} decimals={3} symbol2={symbol}/>
                <img src={Logo} style={{ height: '26px', marginLeft: 10, marginTop: 4 }} alt=""/>
              </InlineBlock>
              <Text style={{ fontSize: 18, textAlign: 'center' }}>
                (${luckPriceInDolls.times(luckUserBalance.toBN()).toFormat(4)})
              </Text>
            </div>
        ) :
          account && (<Loader/>)
        }
      </CenterBlock>
    </Card>
  )
}

export default UserWalletCard
