import { useEffect, useState } from 'react'
import BN from 'bignumber.js'

import useLuck from './useLuck'
import { getMasterContract } from '../luck/utils'
import useBlock from './useBlock'

export interface TopGamblers {
  player: string
  transactions: [string]
  totalVolumeWager: BN
  totalGain: BN
  totalNetGain: BN
}

export const getLast24hoursBlock = (currentBlock: number) => {
  const numberOfBlockInMinute = 20
  const numberOfHours = 24
  return currentBlock - numberOfHours * numberOfBlockInMinute * 60
}

export const getBlockByDate = (currentBlock: number, timestamp: number) => {
  if (!currentBlock) return 0

  const numberOfBlockInMinute = 20
  // @ts-ignore
  const numberOfMinutes = (new Date(timestamp) - new Date()) / (60 * 1000)
  return currentBlock + Math.trunc(numberOfMinutes) * numberOfBlockInMinute
}

export const getGain = (diceResult: BN, probabilityToWin: BN, userBet: BN, fee: BN) => {
  if (diceResult.gt(probabilityToWin)) return new BN(0)
  return new BN(1)
    .div(probabilityToWin.div(100))
    .times(userBet)
    .minus(fee)
}

export const getNetGain = (diceResult: BN, probabilityToWin: BN, userBet: BN, fee: BN) => {
  if (diceResult.gt(probabilityToWin)) return new BN(0)
  return userBet.minus(
    new BN(1)
      .div(probabilityToWin.div(100))
      .times(userBet)
      .minus(fee)
  )
}

export default function useTopPlayers(
  startDate: number = 0,
  endDate: number = 0,
  sortByNetGain: boolean = false
): TopGamblers[] {
  const luck = useLuck()
  const block = useBlock()
  const masterContract = getMasterContract(luck)
  const [bets, setBets] = useState<TopGamblers[]>([])

  useEffect(() => {
    ;(async () => {
      if (!masterContract) return

      const filterFrom = masterContract.filters.Bet(null, null, null)
      const result = await masterContract.queryFilter(
        filterFrom,

        startDate ? getBlockByDate(block, startDate) : getLast24hoursBlock(block),
        endDate ? getBlockByDate(block, endDate) : undefined
      )
      setBets(
        result
          .reduce((acc: any, bet) => {
            if (!bet) return acc

            const player = bet?.args?.player ?? ''
            const playerInLeaderboardIndex = acc.findIndex((bet: { player: any }) => bet?.player === player)
            if (playerInLeaderboardIndex <= -1)
              return [
                ...acc,
                {
                  player: bet?.args?.player ?? '',
                  transactions: [bet.transactionHash],
                  totalVolumeWager: new BN(bet?.args?.amount?.toString() ?? 0),
                  totalNetGain: getNetGain(
                    new BN(bet?.args?.dice?.toString() ?? 0).plus(1),
                    new BN(bet?.args?.betMask?.toString() ?? 0),
                    new BN(bet?.args?.amount?.toString() ?? 0),
                    new BN(0.01)
                  ),
                  totalGain: getGain(
                    new BN(bet?.args?.dice?.toString() ?? 0).plus(1),
                    new BN(bet?.args?.betMask?.toString() ?? 0),
                    new BN(bet?.args?.amount?.toString() ?? 0),
                    new BN(0.01)
                  )
                }
              ]

            const playerInLeaderboard = acc[playerInLeaderboardIndex]
            acc[playerInLeaderboardIndex] = {
              player: playerInLeaderboard.player,
              transactions: [...playerInLeaderboard.transactions, bet.transactionHash],
              totalVolumeWager: playerInLeaderboard.totalVolumeWager.plus(new BN(bet?.args?.amount?.toString() ?? 0)),
              totalNetGain: playerInLeaderboard.totalNetGain.plus(
                getNetGain(
                  new BN(bet?.args?.dice?.toString() ?? 0).plus(1),
                  new BN(bet?.args?.betMask?.toString() ?? 0),
                  new BN(bet?.args?.amount?.toString() ?? 0),
                  new BN(0.01)
                )
              ),
              totalGain: playerInLeaderboard.totalGain.plus(
                getGain(
                  new BN(bet?.args?.dice?.toString() ?? 0).plus(1),
                  new BN(bet?.args?.betMask?.toString() ?? 0),
                  new BN(bet?.args?.amount?.toString() ?? 0),
                  new BN(0.01)
                )
              )
            }
            return acc
          }, [])
          .sort((a: any, b: any) => {
            if (sortByNetGain) return a.totalGain.gt(b.totalGain) ? -1 : 1

            return a.totalVolumeWager.gt(b.totalVolumeWager) ? -1 : 1
          })
      )
    })()
  }, [block, masterContract, startDate, endDate, sortByNetGain])

  return bets
}
