import { Contract } from '@ethersproject/contracts'
import { useMemo } from 'react'
import ERC20 from '../constants/abi/ERC20.json'
import { MULTICALL_ABI, MULTICALL_ADDRESS_LIST } from '../constants/multicall'
import { Token } from '../luck'
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'

/**
 * Use to build contract
 * @param address
 * @param ABI
 * @param withSignerIfPossible
 */
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(token: Token | string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(token instanceof Token ? token.address : token, ERC20.abi, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20.abi, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_ADDRESS_LIST[chainId], MULTICALL_ABI, false)
}
