import React, { useCallback } from 'react'
import { Text } from 'rebass'

import Logo from '../../../assets/svg/coin.svg'
import Card from '../../../components/Card'
import Timer from 'react-compound-timer/build/components/Timer/Timer'
import styled from 'styled-components'
import { ImgSpin } from '../../Home'
import { InlineBlock } from '../../Luckyroll'
import { ExternalLink } from '../../../theme'
import Winners from './Winners'
import { ColWithMobileMargin } from '../index'
import useTopPlayers from '../../../hooks/useTopPlayers'
import Jackpot from './Jackpot'

const TimerText = styled(Text)`
  color: ${({ theme }) => theme.colors.green1};
`
const ImgSpinCoin = styled(ImgSpin)`
  height: 18px;
  position: absolute;
`
const ExtLink = styled(ExternalLink)`
  font-family: GothamRoundedBook;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.green1};
`

const AirdropTimer = ({ endDate }: { endDate: number }) => (
  <Timer initialTime={endDate - new Date(Date.now()).getTime()} direction="backward">
    {() => (
      <TimerText fontWeight={600} fontSize={24} textAlign="center">
        <Timer.Days formatValue={value => (value ? `${value} Days ` : '')} />
        <Timer.Hours formatValue={value => (value ? `${value}h:` : '')} />
        <Timer.Minutes formatValue={value => (value ? `${value}m:` : '')} />
        <Timer.Seconds formatValue={value => (value ? `${value}s` : '')} />
      </TimerText>
    )}
  </Timer>
)

const AirdropCard = ({
  id,
  descriptionOver,
  descriptionInProgress,
  startDate,
  endDate,
  twitterLink,
  showLeaderboard,
  sortByNetGain,
  top,
  leaderboardTitle
}: {
  id: string
  descriptionOver: string
  descriptionInProgress: string
  startDate: number
  endDate: number
  twitterLink: string
  showLeaderboard: boolean
  sortByNetGain: boolean
  top: number
  leaderboardTitle: string
}) => {
  const topPlayers = useTopPlayers(startDate, endDate, sortByNetGain)
  const MemoTimer = useCallback(() => <AirdropTimer endDate={endDate} />, [endDate])

  if (new Date().getTime() > endDate)
    return (
      <ColWithMobileMargin xs={12} md={8} style={{ margin: 'auto' }}>
        <Card>
          <Text fontWeight={600} fontSize={20} textAlign="center">
            Airdrop {id} is over
          </Text>
          <Text fontSize={16} fontWeight={700} style={{ padding: 20, textAlign: 'center' }}>
            {descriptionOver}
          </Text>
          <Jackpot topPlayers={topPlayers} />
          <InlineBlock style={{ marginTop: 10 }}>
            <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
              Start Date:
            </Text>
            <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
              {new Date(startDate).toUTCString()}
            </Text>
          </InlineBlock>
          <InlineBlock>
            <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
              End Date:
            </Text>
            <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
              {new Date(endDate).toUTCString()}
            </Text>
          </InlineBlock>
          <InlineBlock style={{padding: '20px 0'}}>
            <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
              To be eligible, please follow
            </Text>
            <ExtLink href={twitterLink} style={{ padding: '5px 0' }}>
              these conditions
            </ExtLink>
          </InlineBlock>
          <InlineBlock>
            <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
              To claim your airdrop, please go
            </Text>
            <ExtLink href={'https://bnex.org/#/airdrop'} style={{ padding: '5px 0' }}>
              here
            </ExtLink>
          </InlineBlock>

          <ImgSpinCoin src={Logo} style={{ top: 15, left: 15 }} alt="" />
          <ImgSpinCoin src={Logo} style={{ top: 15, right: 15 }} alt="" />
          <ImgSpinCoin src={Logo} style={{ bottom: 15, left: 15 }} alt="" />
          <ImgSpinCoin src={Logo} style={{ bottom: 15, right: 15 }} alt="" />
        </Card>
        {showLeaderboard && (
          <div style={{ marginTop: 40 }}>
            <Winners topPlayers={topPlayers} top={top} leaderboardTitle={leaderboardTitle} />
          </div>
        )}
      </ColWithMobileMargin>
    )

  return (
    <ColWithMobileMargin xs={12} md={8} style={{ margin: 'auto' }}>
      <Card>
        <Text fontWeight={600} fontSize={20} textAlign="center">
          End of Airdrop {id} in <MemoTimer />
        </Text>
        <Text fontSize={16} fontWeight={700} style={{ padding: 20, textAlign: 'center' }}>
          {descriptionInProgress}
        </Text>
        <Jackpot topPlayers={topPlayers} />
        <InlineBlock style={{ marginTop: 10 }}>
          <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
            Start Date:
          </Text>
          <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
            {new Date(startDate).toUTCString()}
          </Text>
        </InlineBlock>
        <InlineBlock>
          <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
            End Date:
          </Text>
          <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
            {new Date(endDate).toUTCString()}
          </Text>
        </InlineBlock>
        <InlineBlock style={{padding: '20px 0'}}>
          <Text fontSize={16} fontWeight={700} style={{ padding: 5 }}>
            To be eligible, please follow
          </Text>
          <ExtLink href={twitterLink} style={{ padding: '5px 0' }}>
            these conditions
          </ExtLink>
        </InlineBlock>

        <ImgSpinCoin src={Logo} style={{ top: 15, left: 15 }} alt="" />
        <ImgSpinCoin src={Logo} style={{ top: 15, right: 15 }} alt="" />
        <ImgSpinCoin src={Logo} style={{ bottom: 15, left: 15 }} alt="" />
        <ImgSpinCoin src={Logo} style={{ bottom: 15, right: 15 }} alt="" />
      </Card>
      {showLeaderboard && (
        <div style={{ marginTop: 40 }}>
          <Winners topPlayers={topPlayers} top={top} leaderboardTitle={leaderboardTitle} />
        </div>
      )}
    </ColWithMobileMargin>
  )
}

export default AirdropCard
