import { useCallback, useEffect, useState } from 'react'
import BN from 'bignumber.js'

import BnEXPairJson from '../constants/abi/BnEXPair.json'

import useBlock from './useBlock'
import { useActiveWeb3React } from '.'
import { Contract } from 'ethers'
import useLuck from './useLuck'
import { ChainId } from '../luck'

const useLuckPrice = () => {
  const [result, setResult] = useState(new BN(0))
  const { account } = useActiveWeb3React()
  const block = useBlock()
  const luck = useLuck()

  const fetchAllStakedValue = useCallback(async () => {
    if (!account || !luck) return
    const lpContract = new Contract(
      luck?.chainId === ChainId.MAINNET
        ? '0xC726aC2Aa9A81B1f67947981443B877500d6683F'
        : '0xc7ce7c885c7e29d913cb03a649bef6883c86d27d',
      BnEXPairJson.abi,
      luck?.provider
    )
    const result = await lpContract.getReserves()
    setResult(new BN(result._reserve1.toString()).div(new BN(result._reserve0.toString())))
  }, [account, luck])

  useEffect(() => {
    fetchAllStakedValue()
  }, [account, block, luck, setResult, fetchAllStakedValue])

  return result
}

export default useLuckPrice
