import styled from 'styled-components'
import { Box } from 'rebass/styled-components'

const Chip = styled(Box)<{ padding?: string; border?: string; borderRadius?: string; shadow?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  backdrop-filter: blur(2px);
  background: rgb(255 255 255 / 0.06);
  box-shadow: none;
  border-radius: 30px;
`
export default Chip
