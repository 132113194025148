import React, { useState } from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import 'rc-slider/assets/index.css'

import Frame from '../../components/Frame'
// import WagerVolume from './components/WagerVolume'
import AirdropCard from './components/AirdropCard'
import { ButtonSecondary } from '../../components/Button'

export const ColWithMobileMargin = styled(Col)`
  padding: 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 24px;
  `};
`

export const SuccessText = styled('div')`
  color: ${({ theme }) => theme.colors.green1};
  font-family: GothamRoundedBold;
`

interface IAirdrops {
  [key: string]: {
    id: string,
    descriptionOver: string,
    descriptionInProgress: string,
    startDate: number,
    endDate: number,
    twitterLink: string,
    showLeaderboard: boolean,
    sortByNetGain: boolean,
    top: number,
    leaderboardTitle: string,
  }
}

export const convertHashmapToArray = (hashmap = {}) =>
// @ts-ignore
  !hashmap ? [] : Object.keys(hashmap).map(key => hashmap[key]);

export default function Airdrops() {
  const [currentAirdropId, setCurrentAirdropId] = useState('3')
  const airdrops: IAirdrops = {
    '1': {
      id: '1',
      descriptionOver: '1% of the first 24 hours wagers volume will be given to the 20 biggest gamblers.',
      descriptionInProgress: '1% of the first 24 hours wager volume to earn for the 20 biggest gamblers.',
      startDate: 1604178000000,
      endDate: 1604264400000,
      twitterLink: 'https://mobile.twitter.com/Luckcoin2/status/1322631347106709512',
      leaderboardTitle: 'Top 20 Gamblers (with big wagers\' volume)',
      top: 20,
      showLeaderboard: true,
      sortByNetGain: false,
    },
    '2': {
      id: '2',
      descriptionOver: '1% of 4 days wagers\' volume will be given to the top 5 of those who brought us the most of new active players.',
      descriptionInProgress: '1% of 4 days wagers\' volume will go to the top 5 of those who bring us the most of new active players.',
      startDate: 1604343600000,
      endDate: 1604689200000,
      twitterLink: 'https://mobile.twitter.com/Luckcoin2/status/1323325086439526407',
      leaderboardTitle: '',
      top: 5,
      showLeaderboard: false,
      sortByNetGain: false
    },
    '3': {
    id: '3',
      descriptionOver: '🍀1% of one week wagers\' volume will be given to the top 20 winners (players with the most gain). See below your position',
      descriptionInProgress: '🍀1% of this week wagers\' volume will go to the top 20 winners (players with the most gain). See below your position',
      startDate: 1604349900000,
      endDate: 1604954700000,
      twitterLink: 'https://mobile.twitter.com/Luckcoin2/status/1323348348150099971',
      leaderboardTitle: 'Top 20 Winners (with big gains)',
      top: 20,
      showLeaderboard: true,
      sortByNetGain: true,
  },
  }
  const airdropsList = convertHashmapToArray(airdrops)
  const handleDisplayAirdrop = (id: string) => {
    setCurrentAirdropId(id)
  }

  return (
    <Frame maxWidth={980}>
      <Container>
        <Row xs={1} sm={24} style={{justifyContent: 'space-around', margin: 'auto', maxWidth: 800}}>
          {airdropsList.map((airdrop, i) => (
            <div style={{ marginTop: 40, maxWidth: 200 }} key={i}>
              <ButtonSecondary onClick={() => handleDisplayAirdrop(airdrop.id)}>
                Airdrop {airdrop.id} ({new Date() >= new Date(airdrop.endDate) ? 'Over' : 'NEW'})
              </ButtonSecondary>
            </div>
            ))}
        </Row>
          <div style={{ marginTop: 40 }}>
            <AirdropCard {...airdrops[currentAirdropId]}/>
          </div>
      </Container>
    </Frame>
  )
}
