import React, { useEffect, useState } from 'react'
import { Text } from 'rebass'

import { TopGamblers } from '../../../hooks/useTopPlayers'
import { getBalanceNumber } from '../../../utils/formatBalance'
import Logo from '../../../assets/svg/coin.svg'
import { BalanceWrapper, InfoCard } from '../../Home'
import Value from '../../../components/Value'
import BN from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'


const WagerVolume = ({ topPlayers}: { topPlayers: TopGamblers[]}) => {
  const { account } = useWeb3React()
  const [wager, setWager] = useState(new BN(0))
  const [lastBetsCount, setLastBetsCount] = useState(0)
  useEffect(() => {
    ;(async () => {
      setLastBetsCount(topPlayers.reduce((acc: any, player: any) => acc + player.transactions.length, 0))
      setWager(topPlayers.reduce((acc: any, player: any) => acc.plus(player.totalVolumeWager), new BN(0)))
    })()
  }, [topPlayers])

  return (
    <InfoCard title={!account ? "Wagers' volume" : "24h wagers' volume"} footer={<Value value={!account ? 3027 : lastBetsCount} decimals={0} symbol2=" bets" />}>
      <BalanceWrapper>
        <Text fontSize={34} fontWeight={700} px={2}>
          <Value value={!account ? '5,226,463.12' : getBalanceNumber(wager)} decimals={1} />
        </Text>

        <img src={Logo} style={{ height: '28px' }} alt=""/>
      </BalanceWrapper>
    </InfoCard>
  )
}

export default WagerVolume
