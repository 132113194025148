import styled, { keyframes, ThemeContext } from 'styled-components'
import React, { useContext } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { Text } from 'rebass'

import { SuccessText } from '../../pages/Luckyroll'
import BN from 'bignumber.js'

const cursorGoingToResult = (result: number) => keyframes`
from {
   left: 0;
  }
  to {
     left: ${result || 100}%;
  }
`
const ResultCursorAnimated = styled<any>('div')`
  width: 3px;
  height: 30px;
  box-shadow: inset rgba(0, 0, 0, 0.35) 2px 2px 12px, red 0 0 10px;
  border: 4px solid red;
  background: ${({ theme }) => theme.colors.primary2};
  position: absolute;
  top: -4px;
  animation: ${({ result }) => cursorGoingToResult(result)} 1.4s ease-in;
  animation-direction: ${({ result }) => (result !== undefined ? 'normal' : 'alternate')};
  animation-iteration-count: ${({ result }) => (result !== undefined ? 1 : 'infinite')};
  animation-fill-mode: forwards;
`
export const sliderWinningChangeMarkStyle = { color: 'white', marginTop: 8 }

const LuckyRollResultSlider = ({
  userWinningChance,
  result,
  possibleGain
}: {
  userWinningChance: number
  result?: number
  possibleGain: BN
}) => {
  const theme = useContext(ThemeContext)
  const userWon = result && result <= userWinningChance
  const userLost = result && !userWon

  return (
    <div>
      <div style={{ position: 'relative', paddingBottom: 20 }}>
        <Slider
          marks={{
            0: {
              label: '0',
              style: { ...sliderWinningChangeMarkStyle, marginTop: -35 }
            },
            100: {
              label: '100',
              style: { ...sliderWinningChangeMarkStyle, marginTop: -35 }
            },
            [userWinningChance]: {
              label: `Your set winning chance: ${userWinningChance}%`,
              style: { ...sliderWinningChangeMarkStyle, marginTop: 12 }
            }
          }}
          dots={false}
          trackStyle={{
            background: theme.colors.green1,
            height: 12
          }}
          railStyle={{
            height: 12
          }}
          handleStyle={{
            width: 1,
            height: 20,
            borderRadius: 2,
            border: `4px solid ${theme.colors.green1}`
          }}
          dotStyle={{
            background: 'transparent',
            border: 0
          }}
          value={userWinningChance}
          min={0}
          max={100}
          style={{ height: 40, marginTop: 15 }}
        />
        <ResultCursorAnimated result={result} />
      </div>
      {result !== undefined && (
        <Text style={{ fontSize: 18, marginTop: 30, textAlign: 'center' }}>Result: {result}</Text>
      )}
      {userWon && (
        <SuccessText style={{ fontSize: 18, marginTop: 10, textAlign: 'center' }}>
          CONGRATULATION!{' '}
          <span role="img" aria-label="Congratulation !">
            🎉
          </span>
          You won {possibleGain.toFixed(2)} LUCK
        </SuccessText>
      )}
      {userLost && (
        <Text style={{ fontSize: 18, marginTop: 10, textAlign: 'center' }}>
          Sorry you lost. Retry once again, the next one will be the lucky one{' '}
          <span role="img" aria-label="Next time you win for sure">
            🍀
          </span>
        </Text>
      )}
    </div>
  )
}

export default LuckyRollResultSlider
