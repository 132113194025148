import BN from 'bignumber.js'
import { Token, TokenAmount } from '../luck'
import { useTokenContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useMemo } from 'react'
import { useActiveWeb3React } from '.'

const useTokenBalance = (token?: Token, wallet?: string) => {
  const { account } = useActiveWeb3React()
  const contract = useTokenContract(token?.address, false)
  const balance: BN = useSingleCallResult(contract, 'balanceOf', [wallet || (account as string)])?.result?.[0]
  return useMemo(() => (token && balance ? new TokenAmount(token, balance.toString()) : undefined), [token, balance])
}

export default useTokenBalance
