import ethers from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { Token, Luck } from './index'
import BN from 'bignumber.js'

BN.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80
})

export const getMasterAddress = (bnex: Luck | undefined): string | undefined => {
  return bnex?.contracts?.master?.address
}
export const getMasterContract = (bnex: Luck | undefined): Contract | undefined => {
  return bnex?.contracts?.master
}
export const getLuckAddress = (bnex: Luck | undefined): string | undefined => {
  return bnex?.contracts?.luck?.address
}
export const getLuckContract = (bnex: Luck | undefined): Contract | undefined => {
  return bnex?.contracts?.luck
}
export const getMulticallAddress = (bnex: Luck | undefined): string | undefined => {
  return bnex?.contracts?.multicall?.address
}
export const getgetMulticallAddressContract = (bnex: Luck | undefined): Contract | undefined => {
  return bnex?.contracts?.multicall
}

/**
 * Use to collect al info bout an ERC20
 * @param bnex
 * @param erc20Contract
 */
export const getERC20Info = async (bnex: Luck, erc20Contract: Contract) => {
  //TODO: should use built-in multicall as hook function
  const calls = [
    [erc20Contract.address, erc20Contract.interface.encodeFunctionData('symbol')],
    [erc20Contract.address, erc20Contract.interface.encodeFunctionData('name')],
    [erc20Contract.address, erc20Contract.interface.encodeFunctionData('decimals')]
  ]
  const aggcalls = await bnex.contracts.multicall.callStatic.aggregate(calls)
  const symbol = ethers.utils.defaultAbiCoder.decode(['string'], aggcalls[1][0])[0]

  return new Token(
    bnex.chainId,
    erc20Contract.address,
    ethers.utils.defaultAbiCoder.decode(['uint256'], aggcalls[1][2])[0],
    symbol === 'WBNB' ? 'BNB' : symbol,
    ethers.utils.defaultAbiCoder.decode(['string'], aggcalls[1][1])[0]
  )
}

// export const stake = async (masterContract: Contract, poolId: string, amount: string, account: string) => {
//   return masterContract.deposit(poolId, amount, {
//     from: account
//   })
// }

export const bet = async (contract: Contract, betMask: string, modulo: string, amount: string, account: string) => {
  // console.log('bet', contract)
  return contract.bet(betMask, modulo, amount, {
    from: account,
    gasLimit: '150000'
  })
}

// export const unstake = async (masterContract: Contract, poolId: string, amount: string, account: string) => {
//   return masterContract.withdraw(poolId, amount, {
//     from: account
//   })
// }
// export const harvest = async (masterContract: Contract, poolId: string, account: string) => {
//   return masterContract.deposit(poolId, '0', { from: account })
// }

// export const getStaked = async (masterContract: Contract, poolId: string, account: string) => {
//   try {
//     const { amount } = await masterContract.userInfo(poolId, account)
//     return amount
//   } catch {
//     return BigNumber.from(0)
//   }
// }

// export const redeem = async (masterContract: Contract, account: string) => {
//   return masterContract.exit({ from: account })
// }
