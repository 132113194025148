import React, { createContext, useEffect, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { Luck } from '../../luck'
import { getProviderOrSigner } from '../../utils'

export interface LuckContext {
  luck?: Luck
}

export const Context = createContext<LuckContext>({
  luck: undefined
})

declare global {
  interface Window {
    luckLib: any
  }
}

const LuckProvider: React.FC = ({ children }) => {
  const { account, chainId, library } = useActiveWeb3React()
  const [luck, setLuck] = useState<any>()
  const ethereum = library?.provider

  // @ts-ignore
  window.luckLib = luck
  // @ts-ignore
  window.eth = ethereum

  useEffect(() => {
    if (account && chainId && library && ethereum) {
      const ethereum = getProviderOrSigner(library, account)
      const luckLib = new Luck(ethereum, chainId, account, undefined)
      setLuck(luckLib)
    }
  }, [account, chainId, library, ethereum])

  return <Context.Provider value={{ luck }}>{children}</Context.Provider>
}

export default LuckProvider
