import React from 'react'

import { Box, Text } from 'rebass'
import Web3Status from '../Web3Status'

export default function PleaseConnectWallet() {
  return (
    <Box py={3}>
      <Text fontSize={14} fontWeight={700} style={{marginBottom: 10, maxWidth: 250, textAlign: 'center'}}>
        To see these information:
      </Text>
      <Web3Status />
    </Box>
  )
}
