import { Colors } from './styled'

const white = '#FFFFFF'
const black = '#000000'
const common = {
  // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
  // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  red1: '#FF6871',
  red2: '#F82D3A',
  green1: '#60bb74',
  yellow1: '#FFE270',
  yellow2: '#F3841E',

  shadowCard: 'rgba(11, 14, 17, 0.6)'
}
const dark = {
  // text
  text1: '#FFFFFF',
  text2: '#C3C5CB',
  text3: '#959595',
  text4: '#565A69',
  text5: '#12181E',

  // backgrounds / greys
  bg1: '#1e1e1e',
  bg2: '#494949',
  bg3: '#40444F',
  bg4: '#565A69',
  bg5: '#6C7284',

  //specialty colors
  modalBG: 'rgba(0,0,0,.425)',
  advancedBG: 'rgba(0,0,0,0.1)',

  //primary colors
  primary1: 'rgb(163, 1, 3)',
  primary2: '#a40000',
  primary3: '#940204',
  primary4: '#376bad70',
  primary5: '#a40000',

  // color text
  primaryText1: '#000',

  // secondary colors
  secondary1: '#FFFFFF',
  secondary2: '#17000b26',
  secondary3: '#17000b26'
}

// const light = {
//   // text
//   text1: '#000000',
//   text2: '#565A69',
//   text3: '#888D9B',
//   text4: '#C3C5CB',
//   text5: '#EDEEF2',

//   // backgrounds / greys
//   bg1: '#FFFFFF',
//   bg2: '#F7F8FA',
//   bg3: '#EDEEF2',
//   bg4: '#CED0D9',
//   bg5: '#888D9B',

//   //specialty colors
//   modalBG: 'rgba(0,0,0,0.3)',
//   advancedBG: 'rgba(255,255,255,0.6)',

//   //primary colors
//   primary1: '#dbb623',
//   primary2: '#ffe068',
//   primary3: '#edd161',
//   primary4: '#F6DDE8',
//   primary5: '#FDEAF1',

//   // color text
//   primaryText1: '#dbb623',

//   // secondary colors
//   secondary1: '#dbb623',
//   secondary2: '#F6DDE8',
//   secondary3: '#FDEAF1'
// }

export function colors(darkMode: boolean): Colors {
  return {
    ...common,
    white,
    black,
    ...dark
  }
}
