import React from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../../theme'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
  margin: 0 auto;
`
const CommunityLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.colors.text2};
  &:not(:last-child) {
    margin-right: 16px;
  }
`

export default function BodyFooter({ style }: { style?: any }) {
  return (
    <Wrapper style={style}>
      <CommunityLink href="https://twitter.com/Luckcoin2">Twitter</CommunityLink>
      <CommunityLink href="https://t.me/luckcoincasino">Telegram</CommunityLink>
      <CommunityLink href="https://github.com/luckcoin-finance">GitHub</CommunityLink>
    </Wrapper>
  )
}
