import React, { useContext, useEffect, useState, Fragment } from 'react'
import styled, { ThemeContext, DefaultTheme } from 'styled-components'
import { Col, Collapse, Container, Row } from 'react-bootstrap'
import { Text } from 'rebass'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import BN from 'bignumber.js'
import { useMorph } from 'react-morph'

import Frame from '../../components/Frame'
import Winners from './components/Winners'
import { Input as NumericalInput } from '../../components/NumericalInput'
import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import Card from '../../components/Card'
import { RowFixed } from '../../components/Row'
import { ExternalLink, LinkStyledButton } from '../../theme'
import useBet from '../../hooks/useBet'
import LuckyRollResultSlider, { sliderWinningChangeMarkStyle } from '../../components/LuckyRollResultSlider'
import { useTxConfirmed } from '../../state/transactions/hooks'
import { decodeParameters } from '../../utils/coderParameters'
import useTokenBalance from '../../hooks/useTokenBalance'
import { LUCK_TOKEN } from '../../luck/entities'
import useLuck from '../../hooks/useLuck'
import UserWalletCard from '../../components/UserWalletCard'
import BetProposals from './components/BetProposals'
import Logo from '../../assets/svg/coin.svg'
import { useWeb3React } from '@web3-react/core'
import useLuckPrice from '../../hooks/useLuckPrice'

export const ColWithMobileMargin = styled(Col)`
  padding: 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 24px;
  `};
`
const BoldText = styled(Text)`
  font-weight: 700;
`
const InfoText = styled(Text)`
  text-align: center;
  padding-top: 10px;
  color: #9c9c9c;
  font-weight: 100;
  font-size: 14px;
`
const PriceInDolls = styled(Text)`
  text-align: center;
  color: white;
  font-weight: 100;
  font-size: 16px;
`
export const CenterBlock = styled('h2')`
  justify-content: end;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

const InlineCenterBlock = styled('div')`
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BetInput = styled(NumericalInput)`
  height: 52px;
  min-width: 180px;
  background: white;
  color: ${({ theme }) => theme.colors.primary2};
  border-radius: 13px;
  font-size: 32px;
  text-align: center;
  max-width: 270px;
  width: 100%;
  padding-right: 117px;
  padding-left: 10px;
`

export const SuccessText = styled('div')`
  color: ${({ theme }) => theme.colors.green1};
  font-family: GothamRoundedBold;
`

const ButtonInputBet = styled('a')`
  font-size: 40px;
  padding: 10px;
  cursor: pointer;
  user-select: none;
`
const VerticalSeparator = styled('div')`
  width: 3px;
  background: white;
  height: 110px;
  margin: 0 17px;
`
export const InlineBlock = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
export const TextUnderlined = styled(Text)`
  text-decoration: underline;
`
const CurrencySymbol = styled(Text)`
  color: ${({ theme }) => theme.colors.bg1};
`

const SliderCard = ({
  probabilityToWin,
  winningMultiplicator,
  possibleGain,
  isPlaying,
  result,
  handleChangeProbabilityToWin,
  morph,
  theme,
  luckPriceInDolls,
}: {
  luckPriceInDolls: BN
  probabilityToWin: number
  winningMultiplicator: BN
  possibleGain: BN
  isPlaying: boolean
  result: number | undefined
  handleChangeProbabilityToWin: (value: number) => void
  morph?: any
  theme: DefaultTheme
}) => {
  const [isOpenedHowItWorksSection, setIsOpenedHowItWorksSection] = useState(false)

  return (
    <Card {...morph}>
      <InlineBlock>
        <CenterBlock>
          <h5>WINNING CHANCE</h5>
          <BoldText>{probabilityToWin}%</BoldText>
        </CenterBlock>
        <VerticalSeparator />
        <CenterBlock>
          <h5>WINNING BET PAYS</h5>
          <BoldText>{winningMultiplicator.toFixed(2)}x</BoldText>
          <SuccessText style={{ fontSize: 16, marginTop: 10, textAlign: 'center' }}>
            you will win {possibleGain.toFixed(2)} LUCK (${luckPriceInDolls.times(possibleGain).toFormat(4)})
          </SuccessText>
        </CenterBlock>
      </InlineBlock>
      {isPlaying ? (
        <LuckyRollResultSlider possibleGain={possibleGain} userWinningChance={probabilityToWin} result={result} />
      ) : (
        <div>
          <Slider
            marks={{
              1: {
                label: '1%',
                style: sliderWinningChangeMarkStyle
              },
              50: {
                label: '50%',
                style: sliderWinningChangeMarkStyle
              },
              96: {
                label: '96%',
                style: sliderWinningChangeMarkStyle
              }
            }}
            dots={false}
            trackStyle={{
              background: theme.colors.primary2,
              height: 12
            }}
            railStyle={{
              height: 12
            }}
            handleStyle={{
              width: 24,
              height: 24,
              background: 'white',
              border: '8px solid'
            }}
            dotStyle={{
              background: 'transparent',
              border: 0
            }}
            defaultValue={50}
            min={1}
            max={96}
            onChange={handleChangeProbabilityToWin}
            style={{ height: 40, marginTop: 15 }}
          />
          <InfoText>Adjust your win chance</InfoText>
          <LinkStyledButton onClick={() => setIsOpenedHowItWorksSection(!isOpenedHowItWorksSection)}>
            How it works?
          </LinkStyledButton>
          <Collapse in={isOpenedHowItWorksSection}>
            <div>
              To play, set your winning chance just above and enter the number of LUCKs you want to bet before to press
              the "PLACE BET" button. A result number, generated randomly between 0 and 100 (included), will appear and
              if this result is little than or equal to your winning chance previously set, you win.
              <br />
              <br />
              When the result is greater than your winning chance, you loose and all of your LUCK are burned.
            </div>
          </Collapse>
        </div>
      )}
    </Card>
  )
}

export default function Luckyroll() {
  const { account } = useWeb3React()
  const [betTxHash, setBetTxHash] = useState()
  const luckPriceInDolls = useLuckPrice()
  const { isConfirmed, txn } = useTxConfirmed(betTxHash)
  const luck = useLuck()
  // const [claimConfirmed, setter] = useState(false)
  // TODO: put LUCK address to useCurrency
  const luckToken = LUCK_TOKEN[luck?.chainId ?? 97]
  const { address: luckAddress } = luckToken
  const luckUserBalance = useTokenBalance(LUCK_TOKEN[luck?.chainId ?? 97])
  const [probabilityToWin, setProbabilityToWin] = useState(49)
  const [result, setResult]: any = useState(undefined)
  const [userBet, setUserBet] = useState(new BN(1))
  const [possibleGain, setPossibleGain] = useState(new BN(0))
  const [winningMultiplicator, setWinningMultiplicator] = useState(new BN(0))
  const [canRender, setCanRender] = useState(false)
  const [userWon, setUserWon] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const theme = useContext(ThemeContext)
  const { width, height } = useWindowSize()
  const morph = useMorph()
  const fee = 1 / 100
  const { onBet } = useBet(probabilityToWin.toString(), '100')

  useEffect(() => {
    setCanRender(true)
  }, [])

  useEffect(() => {
    const gain = new BN(1)
      .div(probabilityToWin / 100)
      .times(userBet)
      .minus(fee)
    setPossibleGain(gain)
    setWinningMultiplicator(gain.div(userBet))
  }, [probabilityToWin, userBet, fee])

  useEffect(() => {
    if (isConfirmed && txn?.receipt) {
      const log = txn?.receipt?.logs.find(
        log => log.topics[0] === '0x38c7256617e396ccc40bafc707556e0af0e3482d152fcaaba890578fcb406c7b'
      )
      if (!log) return
      const decode = decodeParameters(['uint256', 'uint256', 'uint256', 'uint256'], log?.data)
      const gameResult = decode[3].toNumber() + 1
      setResult(gameResult)
      if (gameResult <= probabilityToWin) {
        setUserWon(true)
      }
      setTimeout(() => {
        setIsPlaying(false)
      }, 5000)
    }
    //eslint-disable-next-line
  }, [isConfirmed, txn])

  if (!canRender) return null

  const handleChangeProbabilityToWin = (value: number) => {
    setProbabilityToWin(value)
  }
  const userBetMoveStep = new BN(0.01)
  const handleMinusUserBet = () => {
    handleChangeUserBet(userBet.minus(userBetMoveStep).toString())
  }
  const handleAddUserBet = () => {
    handleChangeUserBet(userBet.plus(userBetMoveStep).toString())
  }
  const handleChangeUserBet = (val: string) => {
    const valNumber = parseFloat(val)
    const minUserBet = 0.5
    if (isNaN(valNumber) || !luckUserBalance) return setUserBet(new BN(0.5))

    const bet = valNumber >= minUserBet ? new BN(valNumber) : new BN(0.5)
    const betWithMax = bet.gte(luckUserBalance.toBN()) ? luckUserBalance.toBN() : bet
    setUserBet(betWithMax)
  }
  const handlePlaceBet = async () => {
    setIsPlaying(true)
    setResult(undefined)
    try {
      const rst = await onBet(new BN(userBet).times(1e18))
      setBetTxHash(rst.hash)
    } catch (e) {
      setIsPlaying(false)
    }
  }

  return (
    <Frame maxWidth={980}>
      <Container>
        {userWon && isConfirmed && (
          <Confetti width={width} height={height} onConfettiComplete={() => setUserWon(false)} recycle={false} />
        )}
        <Row xs={1} md={2} style={{ justifyContent: 'space-between', marginBottom: '24px' }}>
          {isPlaying && (
            <Col xs={12} sm={12} md={12} lg={12} style={{ padding: 0 }}>
              <SliderCard
                luckPriceInDolls={luckPriceInDolls}
                morph={morph}
                probabilityToWin={probabilityToWin}
                winningMultiplicator={winningMultiplicator}
                possibleGain={possibleGain}
                isPlaying={isPlaying}
                result={result}
                handleChangeProbabilityToWin={handleChangeProbabilityToWin}
                theme={theme}
              />
            </Col>
          )}

          {!isPlaying && (
            <Fragment>
              <Col xs={12} md={6} style={{ padding: 0 }}>
                <SliderCard
                  luckPriceInDolls={luckPriceInDolls}
                  morph={morph}
                  probabilityToWin={probabilityToWin}
                  winningMultiplicator={winningMultiplicator}
                  possibleGain={possibleGain}
                  isPlaying={isPlaying}
                  result={result}
                  handleChangeProbabilityToWin={handleChangeProbabilityToWin}
                  theme={theme}
                />
                <Card style={{ marginTop: 40 }}>
                  <CenterBlock>
                    <InlineCenterBlock style={{ marginBottom: 0 }}>
                      <ButtonInputBet onClick={handleMinusUserBet}>-</ButtonInputBet>
                      <RowFixed gap="4px" style={{ position: 'relative' }}>
                        <BetInput value={userBet?.toFixed(2)} onUserInput={handleChangeUserBet} />
                        <RowFixed gap="4px" style={{ position: 'absolute', right: 20 }}>
                          <img src={Logo} style={{ height: '22px' }} alt="" />
                          <CurrencySymbol fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
                            LUCK
                          </CurrencySymbol>
                        </RowFixed>
                      </RowFixed>
                      <ButtonInputBet onClick={handleAddUserBet}>+</ButtonInputBet>
                    </InlineCenterBlock>
                  </CenterBlock>
                  <InlineBlock style={{ alignItems: 'center' }}>
                    <InfoText style={{ paddingTop: 0, alignItems: 'center' }}>Your bet: </InfoText>
                    <PriceInDolls style={{ paddingLeft: 4 }}>
                      ${luckPriceInDolls.times(userBet).toFormat(4)}
                    </PriceInDolls>
                  </InlineBlock>
                  <BetProposals setUserBet={setUserBet} userBet={userBet} handleChangeUserBet={handleChangeUserBet} />
                  <InlineBlock style={{ marginTop: 20 }}>
                    <Text>No LUCK in wallet?</Text>
                    <TextUnderlined>
                      <ExternalLink
                        href={`https://bnex.org/#/swap?outputCurrency=${luckAddress}`}
                        style={{ color: theme.colors.green1, paddingLeft: 6 }}
                      >
                        Buy LUCK
                      </ExternalLink>
                    </TextUnderlined>
                  </InlineBlock>
                </Card>
                <AutoColumn gap="12px" justify={'center'}>
                  <ButtonPrimary
                    size="lg"
                    onClick={handlePlaceBet}
                    style={{ margin: '20px 0 40px 0', maxWidth: 250 }}
                    disabled={isPlaying || !luckUserBalance}
                  >
                    <Text fontWeight={700} fontSize={24}>
                      PLACE BET
                    </Text>
                  </ButtonPrimary>
                </AutoColumn>
              </Col>

              <ColWithMobileMargin xs={12} md={5} style={{ textAlign: '-webkit-right' }}>
                {account && (<UserWalletCard />)}
                <div style={{ marginTop: 40 }}>
                  <Winners />
                </div>
              </ColWithMobileMargin>
            </Fragment>
          )}
        </Row>
      </Container>
    </Frame>
  )
}
