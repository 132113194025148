import React, { useEffect, useState, ReactNode } from 'react'
import BN from 'bignumber.js'
import styled, { keyframes } from 'styled-components'
import { Flex, Text, Box } from 'rebass'
import { Link } from 'react-router-dom'
//@ts-ignore
import numeral from 'numeral'
import { Col, Container, Row } from 'react-bootstrap'

import { useDarkModeManager } from '../../state/user/hooks'
import { ExternalLink } from '../../theme'
import { isMobile } from 'react-device-detect'
import Logo from '../../assets/svg/coin.svg'
import LogoDark from '../../assets/svg/coin.svg'
import MoneyBag from '../../assets/svg/money-bag-luck.svg'
import Stonks from '../../assets/svg/stonks.svg'
import LuckyRollIcon from '../../assets/svg/luckyroll.svg'
import SlotMachine from '../../assets/svg/slot_luck.svg'
import LuckFlipIcon from '../../assets/svg/coin_flip_luck.svg'
import Card, { GameCard } from '../../components/Card'
import Chip from '../../components/Chip'
import Value from '../../components/Value'
import Frame from '../../components/Frame'
import { ButtonSecondary, ButtonGray } from '../../components/Button'
import { useTokenTotalSupply } from '../../hooks/useTotkenTotalSupply'
import useLuck from '../../hooks/useLuck'
import { LUCK_TOKEN } from '../../luck/entities/token'
import { getMasterContract } from '../../luck/utils'
import useBlock from '../../hooks/useBlock'
import { getBalanceNumber } from '../../utils/formatBalance'
import { shortenAddress } from '../../utils'
import { isNil } from 'lodash'
import { getLast24hoursBlock } from '../../hooks/useLogBets'
import useLuckPrice from '../../hooks/useLuckPrice'
import { useWeb3React } from '@web3-react/core'
import Loader from '../../components/Loader'
import PleaseConnectWallet from '../../components/PleaseConnectWallet'

export const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  margin: auto auto;
`

const IconLogo = styled.div`
  flex-shrink: 0;
  margin-right: 20px;
`

const IconLogoList = styled.div`
  flex-shrink: 0;
  margin-left: 5%;
  line-height: 1.25rem;
`

const rotate = keyframes`
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
`

// const rotateY = keyframes`
//     from {
//       -webkit-transform: rotateY(0deg);
//     }
//     to {
//       -webkit-transform: rotateY(-1800deg);
//     }
// `

// const rotateX = keyframes`
//     0% {
//       -webkit-transform: translateY(0deg) rotateX(90deg);
//     }
//
//     25% {
//       -webkit-transform: translateY(-20px) rotateX(180deg);
//     }
//
//     50% {
//       -webkit-transform: translateY(-20px) rotateX(360deg);
//     }
//
//     75% {
//       -webkit-transform: translateY(-20px) rotateX(0deg);
//     }
//
//     100% {
//       -webkit-transform: translateY(0px) rotateX(0deg);
//     }
// `

// const ImgRotate = styled.img`
//   animation: ${rotateY} 10s infinite ease-in;
// `

export const ImgSpin = styled.img`
  animation: ${rotate} 8s infinite linear;
`

const ExtLink = styled(ExternalLink)`
  font-family: GothamRoundedBook;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.text3};
`

const InlineTextBlock = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

interface InfoCardType {
  title: ReactNode
  children: ReactNode
  footer?: ReactNode
}

export const InfoCard = ({ title, children, footer }: InfoCardType) => (
  <Card>
    <Box mx="auto">
      <Text>{title}</Text>
    </Box>
    <Box py={3} mx="auto">
      {children}
    </Box>
    {footer && (
      <Box mx="auto">
        <Text>{footer}</Text>
      </Box>
    )}
  </Card>
)

export const ColWithMobileMargin = styled(Col)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 24px;
  `};
`

export const getPlayerWager = (player: string, bets: any[]) =>
  bets.reduce((prev, current) => (current?.player === player ? current.amount.plus(prev) : prev), new BN(0))

export const getPlayerBetCount = (player: string, bets: any[]) =>
  bets.reduce((counter: number, current) => (current?.player === player ? counter + 1 : counter), 0)

export default function Home() {
  const { account } = useWeb3React()
  const [isDark] = useDarkModeManager()
  const [lastWinners, setLastWinners] = useState<any[]>([])
  const [lastBetsCount, setLastBetsCount] = useState(0)
  const [wager, setWager] = useState(new BN(0))
  const luck = useLuck()
  const block = useBlock()
  const masterContract = getMasterContract(luck)
  const price = useLuckPrice()
  const luckTotalSupply = useTokenTotalSupply(LUCK_TOKEN[luck?.chainId ?? 97])
  // const luckUserBalance = useTokenBalance(LUCK_TOKEN[luck?.chainId ?? 97])
  // console.log(luckUserBalance?.toBN().toString()) // Get user balance

  useEffect(() => {
    ;(async () => {
      if (!masterContract) return
      const filterFrom = masterContract.filters.Bet(null, null, null)
      const result = await masterContract.queryFilter(filterFrom, getLast24hoursBlock(block))

      setLastBetsCount(result.length)
      setWager(
        result
          .map(log => new BN(log?.args?.amount?.toString() ?? 0))
          .reduce((prev: BN, val: BN) => val.plus(prev), new BN(0))
      )

      const winningBets = result
        .map(log => ({
          amount: new BN(log?.args?.amount?.toString() ?? 0),
          betMask: new BN(log?.args?.betMask?.toString() ?? 0),
          dice: new BN(log?.args?.dice?.toString() ?? 0),
          player: log?.args?.player ?? ''
        }))
        .filter(({ dice, betMask }) => dice <= betMask)

      const players = [...new Set(winningBets.map(({ player }) => player))]

      const winners = players
        .map((player: string) => ({
          player,
          betCount: getPlayerBetCount(player, winningBets),
          wager: getPlayerWager(player, winningBets)
        }))
        .sort((a, b) => {
          if (a.wager.gt(b.wager)) return -1
          if (a.wager.lt(b.wager)) return 1
          return 0
        })

      setLastWinners(winners.slice(0, 3))
    })()
  }, [masterContract, block])

  return (
    <Frame maxWidth={800}>
      <Container>
        <Row xs={1} sm={24} md={24} lg={24}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Box py={2}>
              <InfoCard
                title={!account ? "Wagers' volume" : "24h wagers' volume"}
                footer={<Value value={!account ? 3027 : lastBetsCount} decimals={0} symbol2=" bets" />}
              >
                <BalanceWrapper>
                  <Text fontSize={34} fontWeight={700} px={2}>
                    <Value value={!account ? '5,226,463.12' : getBalanceNumber(wager)} decimals={1} />
                  </Text>

                  <img src={isDark ? LogoDark : Logo} style={{ height: '28px' }} alt="" />
                </BalanceWrapper>
              </InfoCard>
            </Box>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6}>
            <Box py={2}>
              <InfoCard title="Last 24h top winners">
                {!account && <PleaseConnectWallet />}
                {account && lastWinners.length === 0 && (
                  <Box py={3}>
                    <Loader />
                  </Box>
                )}
                {lastWinners.map((winner, index) => (
                  <Flex key={index}>
                    {isMobile ? (
                      <>
                        <Box width={1 / 2} px={4}>
                          <ExtLink href={`https://bscscan.com/address/${winner.player}`}>
                            {shortenAddress(winner.player, 4)}
                          </ExtLink>
                        </Box>
                        <Box width={1 / 2} px={4}>
                          <Flex mx={-2}>
                            <Box width={7 / 8} px={2}>
                              <Text fontSize={18} fontWeight={400}>
                                <InlineTextBlock>
                                  {numeral(getBalanceNumber(winner.wager)).format('0,0.00 a')}
                                </InlineTextBlock>
                              </Text>
                            </Box>
                            <Box width={1 / 8} px={2}>
                              <IconLogoList>
                                <img src={isDark ? LogoDark : Logo} style={{ height: '16px' }} alt="" />
                              </IconLogoList>
                            </Box>
                          </Flex>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box width={1 / 3} px={4}>
                          <ExtLink href={`https://bscscan.com/address/${winner.player}`}>
                            {shortenAddress(winner.player, 4)}
                          </ExtLink>
                        </Box>
                        <Box width={1 / 3} px={4}>
                          <Text fontSize={18} fontWeight={400}>
                            <InlineTextBlock>{winner.betCount} wins</InlineTextBlock>
                          </Text>
                        </Box>
                        <Box width={1 / 3} px={4}>
                          <Flex mx={-2}>
                            <Box width={7 / 8} px={2}>
                              <Text fontSize={18} fontWeight={400}>
                                <InlineTextBlock>
                                  {numeral(getBalanceNumber(winner.wager)).format('0,0.00 a')}
                                </InlineTextBlock>
                              </Text>
                            </Box>
                            <Box width={1 / 8} px={2}>
                              <IconLogoList>
                                <img src={isDark ? LogoDark : Logo} style={{ height: '16px' }} alt="" />
                              </IconLogoList>
                            </Box>
                          </Flex>
                        </Box>
                      </>
                    )}
                  </Flex>
                ))}
              </InfoCard>
            </Box>
          </Col>
        </Row>

        <Row xs={2} md={2} style={{ justifyContent: 'center', marginBottom: '24px' }}>
          <Col xs={12} sm={6} md={6} lg={4}>
            <GameCard>
              <Box mx="auto" pb={3}>
                <img alt="coin flip" src={LuckFlipIcon} height={80} width={80} />
              </Box>
              <Box mx="auto">
                <Text fontSize={30}>LuckyFlip</Text>
              </Box>
              <Box mx="auto" py={4}>
                <Text fontSize={16} textAlign="center">
                  50% win chance, Winning bet pays 1.98×
                </Text>
              </Box>
              <Box py={3}>
                <ButtonGray>Coming soon...</ButtonGray>
              </Box>
            </GameCard>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4}>
            <GameCard>
              <Box mx="auto" pb={3}>
                <ImgSpin src={LuckyRollIcon} height={80} width={80} />
              </Box>
              <Box mx="auto">
                <Text fontSize={30}>LuckyRoll</Text>
              </Box>
              <Box mx="auto" py={4}>
                <Text fontSize={16} textAlign="center">
                  Choose your win chance, 1% to 96%, and winning bet pays up to 99x
                </Text>
              </Box>
              <Box py={3}>
                <Link to="/luckyroll">
                  <ButtonSecondary>Play</ButtonSecondary>
                </Link>
              </Box>
            </GameCard>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4}>
            <GameCard>
              <Box mx="auto" pb={3}>
                <img src={SlotMachine} height={80} width={80} alt="LuckySlot" />
              </Box>
              <Box mx="auto">
                <Text fontSize={30}>Slot Machine</Text>
              </Box>
              <Box mx="auto" py={4}>
                <Text fontSize={16} textAlign="center">
                  What's a casino without a slot machine ?
                </Text>
              </Box>
              <Box py={3}>
                <ButtonGray>Coming soon...</ButtonGray>
              </Box>
            </GameCard>
          </Col>
        </Row>
        {account && (
          <Row xs={2} md={2} style={{ justifyContent: 'center', marginBottom: '24px', marginTop: '4%' }}>
            <Col xs={12} sm={12} md={4} lg={4}>
              <Box py={2}>
                <Chip>
                  <BalanceWrapper>
                    <IconLogo>
                      <img src={isDark ? LogoDark : Logo} style={{ height: '28px' }} alt="" />
                    </IconLogo>

                    <InlineTextBlock>
                      <Text fontSize={24} fontWeight={700}>
                        {`$${price.toFormat(8)}`}
                      </Text>

                      <Text fontSize={12} fontWeight={300}>
                        &nbsp; Price
                      </Text>
                    </InlineTextBlock>
                  </BalanceWrapper>
                </Chip>
              </Box>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <Box py={2}>
                <Chip>
                  <BalanceWrapper>
                    <IconLogo>
                      <img src={Stonks} style={{ height: '28px' }} alt="" />
                    </IconLogo>

                    <InlineTextBlock>
                      <Text fontSize={24} fontWeight={700}>
                        {isNil(luckTotalSupply) ? '--' : `$${price.times(luckTotalSupply.toBN()).toFormat(2)}`}
                      </Text>
                      <Text fontSize={12} fontWeight={300}>
                        &nbsp; Market Cap.
                      </Text>
                    </InlineTextBlock>
                  </BalanceWrapper>
                </Chip>
              </Box>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <Box py={2}>
                <Chip>
                  <BalanceWrapper>
                    <IconLogo>
                      <img src={MoneyBag} style={{ height: '28px' }} alt="" />
                    </IconLogo>

                    <InlineTextBlock>
                      <Text fontSize={24} fontWeight={700}>
                        {isNil(luckTotalSupply) ? '--' : numeral(luckTotalSupply.toBN().toString()).format('0,0')}
                      </Text>

                      <Text fontSize={12} fontWeight={300}>
                        &nbsp; Supply
                      </Text>
                    </InlineTextBlock>
                  </BalanceWrapper>
                </Chip>
              </Box>
            </Col>
          </Row>
        )}
      </Container>
    </Frame>
  )
}
