import { ethers } from 'ethers'

export const encodeParameters = (types: string[], values: any[]) => {
  const abi = new ethers.utils.AbiCoder()
  return abi.encode(types, values)
}
export const decodeParameters = (types: string[], values: string) => {
  const abi = new ethers.utils.AbiCoder()
  return abi.decode(types, values)
}
