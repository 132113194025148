import React from 'react'
import styled from 'styled-components'

export interface ModalTitleProps {
  text?: string
}

export const ModalTitle: React.FC<ModalTitleProps> = ({ text }) => <StyledModalTitle>{text}</StyledModalTitle>

const StyledModalTitle = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.text1};
  display: flex;
  font-size: 18px;
  font-weight: 700;
  height: ${props => props.theme.spacing[3]}px;
  justify-content: center;
  margin-top: ${props => props.theme.spacing[4]}px;
  margin-bottom: ${props => props.theme.spacing[4]}px;
`
