import React, { useEffect, useState } from 'react'
import { Text } from 'rebass'

import { TopGamblers } from '../../../hooks/useTopPlayers'
import { getBalanceNumber } from '../../../utils/formatBalance'
import Logo from '../../../assets/svg/coin.svg'
import { BalanceWrapper } from '../../Home'
import Value from '../../../components/Value'
import BN from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { InlineBlock } from '../../Luckyroll'
import styled from 'styled-components'

const JackpotValue = styled(BalanceWrapper)`
  background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
  padding: 20px;
  color: black;
  border-radius: 20px;
  position: relative;
    margin: 25px 0;
`
const JackpotLabel = styled(Text)`
  position: absolute;
  color: white;
  top: -16px;
  left: 0;
`

const Jackpot = ({ topPlayers }: { topPlayers: TopGamblers[] }) => {
  const { account } = useWeb3React()
  const [wager, setWager] = useState(new BN(0))
  useEffect(() => {
    ;(async () => {
      setWager(topPlayers.reduce((acc: any, player: any) => acc.plus(player.totalVolumeWager), new BN(0)).times(1/100))
    })()
  }, [topPlayers])
  if (wager.isEqualTo(0)) return null;

  return (
    <InlineBlock>
      <JackpotValue>
        <JackpotLabel fontSize={12} fontWeight={700} px={2}>
          Current Jackpot
        </JackpotLabel>
        <Text fontSize={34} fontWeight={700} px={2}>
          <Value value={!account ? '5,226,463.12' : getBalanceNumber(wager)} decimals={1}/>
        </Text>

        <img src={Logo} style={{ height: '28px' }} alt=""/>
      </JackpotValue>
    </InlineBlock>
  )
}

export default Jackpot
