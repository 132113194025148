import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { darken, lighten } from 'polished'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Link } from 'react-router-dom'
import { ButtonProps, StyledButtonProps } from './styled'

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: unset;
  }
`

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: unset;
  }
`

export const ButtonPrimary: React.FC<ButtonProps> = ({
  children,
  disabled,
  href,
  onClick,
  size,
  to,
  variant,
  style,
  animated,
  id,
  className
}) => {
  const { colors, spacing } = useContext(ThemeContext)

  let buttonColor: string
  switch (variant) {
    case 'secondary':
      buttonColor = colors.text3
      break
    case 'default':
    default:
      buttonColor = colors.text5
  }

  let boxShadow: string
  let buttonSize: number
  let buttonPadding: number
  let fontSize: number
  switch (size) {
    case 'xs':
      boxShadow = `2px 2px 6px ${colors.shadowCard},
        -4px -4px 10px ${colors.black}FF;`
      buttonPadding = spacing[2]
      buttonSize = 28
      fontSize = 14
      break
    case 'sm':
      boxShadow = `4px 4px 8px ${colors.shadowCard},
        -8px -8px 16px ${colors.black}FF;`
      buttonPadding = spacing[3]
      buttonSize = 36
      fontSize = 14
      break
    case 'lg':
      boxShadow = `6px 6px 12px ${colors.shadowCard},
        -12px -12px 24px ${colors.black}ff;`
      buttonPadding = spacing[4]
      buttonSize = 56
      fontSize = 16
      break
    case 'md':
    default:
      boxShadow = `6px 6px 12px ${colors.shadowCard},
        -12px -12px 24px -2px ${colors.black}ff;`
      buttonPadding = spacing[4]
      buttonSize = 48
      fontSize = 16
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledLink to={to}>{children}</StyledLink>
    } else if (href) {
      return (
        <StyledExternalLink href={href} target="__blank">
          {children}
        </StyledExternalLink>
      )
    } else {
      return children
    }
  }, [href, children, to])

  return (
    <StyledButton
      boxShadow={boxShadow}
      color={buttonColor}
      disabled={disabled}
      fontSize={fontSize}
      onClick={onClick}
      padding={buttonPadding}
      size={buttonSize}
      style={style}
      animated={!!animated}
      id={id}
      className={className}
    >
      {ButtonChild}
    </StyledButton>
  )
}

const StyledButton = styled.button<StyledButtonProps>`
  text-decoration: none;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: #a40000;
  color: white;
  border: 0;
  border-radius: 20px; //TODO:
  cursor: pointer;
  display: flex;
  font-size: ${props => props.fontSize}px;
  font-weight: 600;
  height: ${props => props.size}px;
  justify-content: center;
  outline: none;
  padding-left: ${props => props.padding}px;
  padding-right: ${props => props.padding}px;
  pointer-events: ${props => (!props.disabled ? undefined : 'none')};
  animation: ${props => (props.animated ? 'pulse 2s infinite' : 'none')};
  width: 100%;
  &:focus {
    outline: unset;
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.colors.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary3)};
  }
  &:hover {
    transform: ${props => (props.disabled ? undefined : 'scale(1.02)')};
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary3)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.colors.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.colors.primary1)};
  }
  &:disabled {
    color: ${props => props.theme.colors.text2};
    background-color: ${props => props.theme.colors.bg4};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: 0.7;
    &:hover {
      background-color: inherit;
    }
  }
  transition: all 0.25s ease;
  box-shadow: ${props => (!props.disabled ? '1px 1px 3px ' + props.theme.colors.bg1 : 'none')};

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(248, 209, 47, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(248, 209, 47, 0.4);
      box-shadow: 0 0 0 0 rgba(248, 209, 47, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
`

export const ButtonLight = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.colors.primary5};
  color: ${({ theme }) => theme.colors.primaryText1};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    outline: unset;
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.03, theme.colors.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.colors.primary5)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.colors.primary5)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.primary5)};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.colors.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonGray = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.colors.bg3};
  color: ${({ theme }) => theme.colors.text2};
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  &:focus {
    outline: unset;
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg2)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg2)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg2)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.1, theme.colors.bg2)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.colors.bg2)};
  }
`

export const ButtonSecondary = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.colors.secondary1};
  color: ${({ theme }) => theme.colors.primary2};
  font-size: 16px;
  border-radius: 8px;

  &:focus {
    outline: unset;
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.colors.primary4};
    background-color: ${({ theme }) => theme.colors.primary2};
    color: white;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary2};
    color: white;
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.colors.primary4};
    background-color: ${({ theme }) => theme.colors.primary2};
    color: white;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary5};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonPink = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.colors.primary1};
  color: white;

  &:focus {
    outline: unset;
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.colors.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.colors.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.colors.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary1};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonOutlined = styled(ButtonPrimary)`
  border: 1px solid ${({ theme }) => theme.colors.bg2};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text1};

  &:focus {
    outline: unset;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.bg4};
  }
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.bg4};
  }
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.bg4};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(ButtonPrimary)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: unset;
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(ButtonPrimary)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.colors.bg1};
  color: black;

  &:focus {
    outline: unset;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonConfirmedStyle = styled(ButtonPrimary)`
  background-color: ${({ theme }) => lighten(0.5, theme.colors.green1)};
  color: ${({ theme }) => theme.colors.green1};
  border: 1px solid ${({ theme }) => theme.colors.green1};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.colors.red1};
  border: 1px solid ${({ theme }) => theme.colors.red1};
  color: ${({ theme }) => theme.colors.text1};

  &:focus {
    outline: unset;
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.colors.red1)};
    background-color: ${({ theme }) => darken(0.05, theme.colors.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.colors.red1)};
    background-color: ${({ theme }) => darken(0.1, theme.colors.red1)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.red1};
    border: 1px solid ${({ theme }) => theme.colors.red1};
  }
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} /> //altDisabledStyle={altDisabledStyle}
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}
