import { useEffect, useState } from 'react'
import BN from 'bignumber.js'

import useLuck from './useLuck'
import { getMasterContract } from '../luck/utils'
import useBlock from './useBlock'

export interface LogBet {
  blockNumber: number
  transactionHash: string
  amount: BN
  betMask: BN
  modulo: BN
  dice: BN
  player: string
}

export const getLast24hoursBlock = (currentBlock: number) => {
  const numberOfBlockInMinute = 20
  const numberOfHours = 24
  return currentBlock - numberOfHours * numberOfBlockInMinute * 60
}

export default function useLogBets(): LogBet[] {
  const luck = useLuck()
  const block = useBlock()
  const masterContract = getMasterContract(luck)
  const [bets, setBets] = useState<LogBet[]>([])

  useEffect(() => {
    ;(async () => {
      if (!masterContract) return
      const filterFrom = masterContract.filters.Bet(null, null, null)
      const result = await masterContract.queryFilter(filterFrom, getLast24hoursBlock(block))
      setBets(
        result
          .map(rst => ({
            amount: new BN(rst?.args?.amount?.toString() ?? 0),
            betMask: new BN(rst?.args?.betMask?.toString() ?? 0),
            modulo: new BN(rst?.args?.modulo?.toString() ?? 0),
            dice: new BN(rst?.args?.dice?.toString() ?? 0).plus(1),
            player: rst?.args?.player ?? '',
            transactionHash: rst.transactionHash,
            blockNumber: rst.blockNumber
          }))
          .sort((a: any, b: any) => (a.blockNumber > b.blockNumber ? -1 : 1))
      )
    })()
  }, [block, masterContract])

  return bets
}
