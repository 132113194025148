import React from 'react'
import { Text } from 'rebass'

import Logo from '../../../assets/svg/coin.svg'
import Card from '../../../components/Card'
import Timer from 'react-compound-timer/build/components/Timer/Timer'
import styled from 'styled-components'
import { ImgSpin } from '../../Home'

const TimerText = styled(Text)`
  color: ${({ theme }) => theme.colors.green1};
`
const ImgSpinCoin = styled(ImgSpin)`
  height: 18px;
  position: absolute;
`

const AirdropCard: React.FC = () => {
  const dateTarget = 1604950814000
  if (new Date().getTime() > dateTarget)
    return (
      <Card>
        <Text fontWeight={600} fontSize={20} textAlign="center">
          Airdrop is over
        </Text>
        <Text fontSize={16} fontWeight={700} style={{ padding: 20 }}>
          1% of the last week wagers volume will be given to the 20 biggest gamblers.
        </Text>

        <ImgSpinCoin src={Logo} style={{ top: 15, left: 15 }} alt="" />
        <ImgSpinCoin src={Logo} style={{ top: 15, right: 15 }} alt="" />
        <ImgSpinCoin src={Logo} style={{ bottom: 15, left: 15 }} alt="" />
        <ImgSpinCoin src={Logo} style={{ bottom: 15, right: 15 }} alt="" />
      </Card>
    )

  return (
    <Card>
      <Text fontWeight={600} fontSize={20} textAlign="center">
        End of Airdrop in{' '}
        <Timer initialTime={dateTarget - new Date(Date.now()).getTime()} direction="backward">
          {() => (
            <TimerText fontWeight={600} fontSize={24} textAlign="center">
              <Timer.Days formatValue={value => (value ? `${value} Days ` : '')} />
              <Timer.Hours formatValue={value => (value ? `${value}h:` : '')} />
              <Timer.Minutes formatValue={value => (value ? `${value}m:` : '')} />
              <Timer.Seconds formatValue={value => (value ? `${value}s` : '')} />
            </TimerText>
          )}
        </Timer>
      </Text>
      <Text fontSize={16} fontWeight={700} style={{ padding: 20 }}>
        1% of the week wager volume to earn for the 20 biggest gamblers.
      </Text>

      <ImgSpinCoin src={Logo} style={{ top: 15, left: 15 }} alt="" />
      <ImgSpinCoin src={Logo} style={{ top: 15, right: 15 }} alt="" />
      <ImgSpinCoin src={Logo} style={{ bottom: 15, left: 15 }} alt="" />
      <ImgSpinCoin src={Logo} style={{ bottom: 15, right: 15 }} alt="" />
    </Card>
  )
}

export default AirdropCard
