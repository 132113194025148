import { ChainId } from '../../luck'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
import { stack as Menu } from 'react-burger-menu'

import styled from 'styled-components'

import Logo from '../../assets/svg/luckcoinred.svg'
import LogoDark from '../../assets/svg/luckcoinred.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { GreyCard } from '../Card'

import { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
import { burgerMenuStyles } from './styles'
import { NavLink } from 'react-router-dom'
import Nav from './Nav'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

export const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled(NavLink)`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  text-decoration-style: unset;

  :hover {
    cursor: pointer;
  }
`

export const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.colors.bg1 : theme.colors.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    flex-direction: column;
  `};
`

export const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

export const NetworkCard = styled(GreyCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-end;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img { 
      // width: 4.5rem;
    }
  `};
`

const HeaderWrapper = styled.header`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
        min-height: 104px;
        margin-bottom: 20px;
     `};
`

const MobileHeader = styled.div`
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.bg1};
`

const MobileLogo = styled.div`
  width: 100%;
  padding: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NETWORK_LABELS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'MAINNET',
  [ChainId.TESTNET]: 'TESTNET'
}

export default function Header() {
  const [canRender, setCanRender] = useState(false)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  useEffect(() => {
    setCanRender(true)
  }, [])
  const { account, chainId } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()
  if (!canRender) return null

  const handleToggleMenu = (menuState: any) => {
    setIsOpenMenu(menuState.isOpen)
  }
  const handleCloseMenu = () => {
    setIsOpenMenu(false)
  }

  return (
    <HeaderWrapper>
      {isMobile ? (
        <MobileHeader
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
          }}
        >
          <Menu styles={burgerMenuStyles} isOpen={isOpenMenu} onStateChange={handleToggleMenu}>
            <h2 style={{ marginBottom: 24 }}>Links</h2>
            <Nav handleCloseMenu={handleCloseMenu} />
            <HeaderControls style={{ flex: '1', justifyContent: 'flex-end', marginTop: 40 }}>
              <HeaderElement>
                <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                  {account && userEthBalance ? (
                    <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                      {userEthBalance.toSignificant(4)} BNB
                    </BalanceText>
                  ) : null}
                  <Web3Status />
                </AccountElement>
              </HeaderElement>
            </HeaderControls>
          </Menu>
          <MobileLogo>
            <Title to="/">
              <UniIcon>
                <img style={{ height: '36px' }} src={isDark ? LogoDark : Logo} alt="logo" />
              </UniIcon>
            </Title>
          </MobileLogo>
        </MobileHeader>
      ) : (
        <HeaderFrame>
          <RowBetween style={{ alignItems: 'center' }} padding="1rem 80px 0 80px">
            <HeaderElement style={{ flex: '1' }}>
              <Title to="/">
                <UniIcon>
                  <img style={{ height: '36px' }} src={isDark ? LogoDark : Logo} alt="logo" />
                </UniIcon>
              </Title>
            </HeaderElement>
            <Nav handleCloseMenu={Function.prototype} />
            <HeaderControls style={{ flex: '1', justifyContent: 'flex-end' }}>
              <HeaderElement>
                <TestnetWrapper>
                  {!isMobile && chainId && NETWORK_LABELS[chainId] && (
                    <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>
                  )}
                </TestnetWrapper>
                <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                  {account && userEthBalance ? (
                    <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                      {userEthBalance.toSignificant(4)} BNB
                    </BalanceText>
                  ) : null}
                  <Web3Status />
                </AccountElement>
              </HeaderElement>
            </HeaderControls>
          </RowBetween>
        </HeaderFrame>
      )}
    </HeaderWrapper>
  )
}
