import React, { Suspense, useState, useEffect } from 'react'
import { Route, Switch, Redirect, HashRouter as Router } from 'react-router-dom'
import styled from 'styled-components'

import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Background from '../assets/svg/background.svg'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'

import Home from './Home'
import Faq from './Faq'
import LuckyRoll from './Luckyroll'
import LuckFlip from './Luckyroll'

import BodyFooter from '../components/BodyFooter'
import CountDown from '../components/CountDown'
import LuckProvider from '../contexts/Luck'
import ModalsProvider from '../contexts/Modals'
import Leaderboard from './Leaderboard'
import Airdrops from './Airdrops'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  background-size: 130px;

  * {
    box-sizing: border-box;
    outline-offset: unset;
    outline: unset;
  }

  button:focus {
    outline: unset;
    outline: unset;
  }
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 150px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - 72px);
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToMedium`
      padding: 16px;
  `};

  z-index: 1;
`

export default function App() {
  const startDate = parseInt(process.env.REACT_APP_DATE_RELEASE_TIMESTAMP ?? '0')
  const [restTime, setRestTime] = useState((startDate - Math.floor(new Date().getTime() / 1000)) * 1000)

  useEffect(() => {
    if (restTime < 0) return
    setInterval(() => setRestTime((startDate - Math.floor(new Date().getTime() / 1000)) * 1000), 1000)
    //eslint-disable-next-line
  }, [])

  return (
    <Suspense fallback={null}>
      <Router>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper style={restTime >= 0 ? {} : { backgroundImage: `url(${Background})` }}>
          <Web3ReactManager>
            <LuckProvider>
              <ModalsProvider>
                {restTime >= 0 ? (
                  <CountDown startDate={startDate} />
                ) : (
                  <>
                    <HeaderWrapper>
                      <Header />
                    </HeaderWrapper>
                    <BodyWrapper>
                      <Popups />
                      <Switch>
                        <Route exact path="/">
                          <Redirect to="/home" />
                        </Route>
                        <Route exact strict path="/home" component={Home} />
                        <Route exact strict path="/faq" component={Faq} />
                        <Route exact strict path="/luckyroll" component={LuckyRoll} />
                        <Route exact strict path="/luckflip" component={LuckFlip} />
                        <Route exact strict path="/leaderboard" component={Leaderboard} />
                        <Route exact strict path="/airdrops" component={Airdrops} />
                        <Route render={() => <Redirect to="/" />} />
                      </Switch>
                    </BodyWrapper>
                    <BodyFooter />
                  </>
                )}
              </ModalsProvider>
            </LuckProvider>
          </Web3ReactManager>
        </AppWrapper>
      </Router>
    </Suspense>
  )
}
