import React from 'react'
import BN from 'bignumber.js'

import { ButtonPrimary, ButtonSecondary } from '../../../../components/Button'
import { InlineBlock, TextUnderlined } from '../../index'
import useLuck from '../../../../hooks/useLuck'
import useTokenBalance from '../../../../hooks/useTokenBalance'
import { LUCK_TOKEN } from '../../../../luck/entities'

const BetProposals = ({setUserBet, userBet, handleChangeUserBet} : {userBet: BN, setUserBet: (value: BN) => void, handleChangeUserBet: any}) => {
  const luck = useLuck()
  const luckUserBalance = useTokenBalance(LUCK_TOKEN[luck?.chainId ?? 97])
  const firstProposal = 1/2
  const secondProposal = 2
  const thirdProposal = 5
  const handleSetMaxUserBet = () => {
    if (!luckUserBalance) return

    handleChangeUserBet(luckUserBalance.toBN().toString())
  }
  const handleOperateUseBet = (multiplier: number) => {
    if (!userBet) return

    handleChangeUserBet(userBet.times(multiplier).toString())
  }
  return (
    <div>
      <InlineBlock>
        <ButtonSecondary size="sm" onClick={() => handleOperateUseBet(firstProposal)} style={{ margin: '20px 5px 0 5px' }}>
          <TextUnderlined fontWeight={500} fontSize={14}>
            ÷2
          </TextUnderlined>
        </ButtonSecondary>
        <ButtonSecondary size="sm" onClick={() => handleOperateUseBet(secondProposal)} style={{ margin: '20px 5px 0 5px' }}>
          <TextUnderlined fontWeight={500} fontSize={14}>
            x{secondProposal}
          </TextUnderlined>
        </ButtonSecondary>
        <ButtonSecondary size="sm" onClick={() => handleOperateUseBet(thirdProposal)} style={{ margin: '20px 5px 0 5px' }}>
          <TextUnderlined fontWeight={500} fontSize={14}>
            x{thirdProposal}
            </TextUnderlined>
        </ButtonSecondary>
        <ButtonPrimary size="sm" onClick={handleSetMaxUserBet} style={{ margin: '20px 5px 0 5px' }}>
          <TextUnderlined fontWeight={500} fontSize={16}>
            Max
          </TextUnderlined>
        </ButtonPrimary>
      </InlineBlock>
    </div>
  )
}

export default BetProposals
