import React, { useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Text } from 'rebass'
import BN from 'bignumber.js'
//@ts-ignore
import numeral from 'numeral'
// import { isMobile } from 'react-device-detect'

import Card from '../../../components/Card'
// import { BIGNUMBER_FMT } from '../../../constants'
import { getBscscanLink, shortenAddress, shortenTransaction } from '../../../utils'
import { ExternalLink, LinkStyledButton } from '../../../theme'
import { useWeb3React } from '@web3-react/core'
import { LightSeparator } from '../../../components/CSS'
import { SuccessText } from '..'
import useLogBets from '../../../hooks/useLogBets'
import { getBalanceNumber } from '../../../utils/formatBalance'
import { isMobile } from 'react-device-detect'
import { useActiveWeb3React } from '../../../hooks'
import PleaseConnectWallet from '../../../components/PleaseConnectWallet'

const EarnCard = styled(Card)`
  max-width: 410px;
  background-repeat: no-repeat;
  background-position: top right;
  color: ${({ theme }) => theme.colors.text1};
  text-align: center;
  padding: 24px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    &:first-child {
      margin-right: initial;
      margin-bottom: 32px;
    }
  `}
  box-shadow: none;
`

const ExtLink = styled(ExternalLink)`
  font-family: GothamRoundedBook;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.text3};
`
const InfoText = styled(Text)`
  color: ${({ theme }) => theme.colors.text3};
`
const InlineTextBlock = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`
const Table = styled.table`
  &&& {
    table,
    th,
    td {
      border-collapse: collapse;
    }
    th,
    td,
    tr {
      padding: 5px;
      text-align: left;
    }
    table {
      width: 100%;
    }
    tbody {
      font-size: 14px;
      display: block;
      overflow-y: scroll;
      max-height: 310px;
    }
    thead {
      display: flex;
    }
    thead > tr {
      display: flex;
      flex: 1;
    }
    thead > tr > th {
      flex: 1;
    }

    tbody > tr {
      display: flex;
      flex: 1;
    }
    tbody > tr > td {
      flex: 1;
    }
  }
`
//
// const Table = styled.table`
//   display: block;
//   &&& {
//     table,
//     th,
//     td {
//       border-collapse: collapse;
//     }
//     th,
//     td,
//     tr {
//       padding: 5px;
//     }
//     th {
//       text-align: center;
//     }
//     table {
//       width: 100%;
//     }
//     thead {
//       display: block;
//     }
//
//     tbody {
//       display: block;
//       overflow-y: scroll;
//       max-height: 310px;
//     }
//     thead,
//     tbody,
//     tr {
//       width: 100%;
//     }
//   }
// `
const Winners: React.FC = () => {
  const { chainId } = useActiveWeb3React()
  const { account } = useWeb3React()
  const [showOnlyUserGames, setShowOnlyUserGames] = useState<boolean>(false)
  const games = useLogBets()

  const userGames = games.filter(game => game.player === account)
  const winners = showOnlyUserGames ? userGames : games
  const theme = useContext(ThemeContext)

  const getGain = (diceResult: BN, probabilityToWin: BN, userBet: BN, fee: BN) => {
    if (diceResult.gt(probabilityToWin)) return new BN(0)
    return new BN(1)
      .div(probabilityToWin.div(100))
      .times(userBet)
      .minus(fee)
  }

  const handleShowOnlyUserHistory = () => {
    setShowOnlyUserGames(!showOnlyUserGames)
  }

  return (
    <EarnCard>
      <div style={{ textAlign: 'end' }}>
        <LinkStyledButton onClick={handleShowOnlyUserHistory}>Only me</LinkStyledButton>
      </div>
      <Text fontSize={24} fontWeight={700} style={{ paddingBottom: 10 }}>
        Luckyroll history
      </Text>
      <LightSeparator />
      <Table>
        <thead>
          <tr>
            <th style={{ paddingRight: 10 }}>Player</th>
            <th style={{ flex: 2 }}>Bet</th>
            <th>Result</th>
            <th>Tx</th>
          </tr>
        </thead>
        <tbody>
        {(!account) && (
          <tr style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0'
          }}>
            <PleaseConnectWallet/>
          </tr>
        )}
          {account && winners &&
            winners.map((winner, i) => {
              const { player, amount, betMask, dice, transactionHash } = winner
              const gain = getGain(dice, betMask, amount, new BN(0.01))
              const userWon = gain.gt(0)
              return (
                <tr key={i}>
                  <td style={{ paddingRight: 10 }}>
                    <ExtLink
                      href={chainId ? getBscscanLink(chainId, player, 'address') : ''}
                      style={{
                        width: '100%',
                        color: account === player ? theme.colors.primary1 : theme.colors.text3
                      }}
                    >
                      {shortenAddress(player, isMobile ? 1 : 2)}
                    </ExtLink>
                  </td>
                  <td style={{ flex: 2 }}>
                    <InlineTextBlock>
                      <Text style={{ paddingRight: 10 }}>{numeral(getBalanceNumber(amount)).format('0[.][00] a')}</Text>
                      <InfoText>≤ {betMask.toString()}</InfoText>
                    </InlineTextBlock>
                  </td>
                  <td>
                    {userWon ? (
                      <InlineTextBlock>
                        <SuccessText style={{ paddingRight: 10 }}>{dice.toString()}</SuccessText>
                        <Text>{numeral(getBalanceNumber(gain)).format('0[.][00] a')}</Text>
                      </InlineTextBlock>
                    ) : (
                      <Text>{dice.toString()} —</Text>
                    )}
                  </td>
                  <td>
                    <ExtLink href={chainId ? getBscscanLink(chainId, transactionHash, 'transaction') : ''}>
                      {shortenTransaction(transactionHash, isMobile ? 1 : 2)}
                    </ExtLink>
                  </td>
                  {/* <td>{new BN(winner.prize.toString()).div(new BN(10).pow(18)).toFormat(0, BIGNUMBER_FMT)}</td>
                  <td>{winner.ticketNumber.toString()}</td> */}
                </tr>
              )
            })}
        </tbody>
      </Table>
    </EarnCard>
  )
}

export default Winners
