import { useContext } from 'react'
import { Context } from '../contexts/Luck'
import { Luck } from '../luck'

/**
 * Use to get the Luck library instance
 */
const useLuck = (): Luck | undefined => {
  const { luck } = useContext(Context)
  return luck
}

export default useLuck
