import React, { useContext, useEffect } from 'react'
import { useCountUp } from 'react-countup'

import styled, { ThemeContext } from 'styled-components'

interface ValueProps {
  value: string | number
  decimals?: number
  symbol?: string
  symbol2?: string
}

const StyledValue = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
`

const Value: React.FC<ValueProps> = ({ value, decimals, symbol, symbol2 }) => {
  const { countUp, update } = useCountUp({
    start: 0,
    end: 0,
    delay: 0,
    duration: 1,
    separator: ',',
    decimals: decimals !== undefined ? decimals : 3
  })
  const theme = useContext(ThemeContext)

  useEffect(() => {
    if (typeof value === 'number') {
      update(value)
    }
  }, [value, update])

  return (
    <StyledValue>
      {symbol}
      {typeof value == 'string' ? value : countUp}
      <div style={{paddingLeft: 8, color: theme.colors.primary1}}>
        {symbol2}
      </div>
    </StyledValue>
  )
}

export default Value
