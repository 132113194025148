import { useCallback } from 'react'
import ReactGA from 'react-ga'
import BN from 'bignumber.js'
import { bet, getMasterContract } from '../luck/utils'
import { useTransactionAdder } from '../state/transactions/hooks'
import useLuck from './useLuck'
import { useActiveWeb3React } from './'

/**
 * Use to place a bet
 */
const useBet = (betMask: string, modulo: string) => {
  const { account } = useActiveWeb3React()
  const luck = useLuck()
  const addTransaction = useTransactionAdder()
  const masterContract = getMasterContract(luck)

  const handleBet = useCallback(
    async (amount: BN) => {
      if (!masterContract || !account || !amount) return
      const txHash = await bet(masterContract, betMask, modulo, amount.toFixed(0), account)
      addTransaction(txHash, {
        summary: `Bet ${amount.div(1e18).toString()} LUCK`,
        bet: { recipient: account }
      })
      ReactGA.event({
        category: 'LuckyRoll',
        action: 'Bet',
        label: `${account}/${amount.toString()}`
      })
      return txHash
    },
    [account, masterContract, addTransaction, betMask, modulo]
  )

  return { onBet: handleBet }
}

export default useBet
