import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import BodyHeader from '../BodyHeader'
import BodyFooter from '../BodyFooter'
import Background from '../../assets/svg/background.svg'
import { timestampToCountDown } from '../../utils/blockTime'

const Canvas = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Lato:400,700');
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
 
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: 23px;

  .logo {
    display: block;
    position: absolute;
    z-index: 9;
    width: 720px;
    height: 500px;
    text-align: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    cursor: default;

    .animate {
      animation: clock-bag 4s linear infinite;
    }
  }

  a.switcher {
    display: block;
    position: fixed;
    text-decoration: none;
    z-index: 999999999999;
    right: 20px;
    bottom: 20px;
    width: 16px;
    height: 16px;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 50%;
    opacity: 0.15;
    -webkit-transition: opacity 0.15s;
    transition: opacity 0.15s;
  }
  a.switcher:hover {
    opacity: 1;
  }
  a.switcher:before {
    display: block;
    content: '';
    position: absolute;
    border-radius: 4px;
    width: 2px;
    height: 5px;
    background: #fff;
    top: 0;
    left: 5px;
  }
  .screen {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .figure {
    display: block;
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-top: 200px solid #fff;
    border-right: 100px solid transparent;
    border-left: 100px solid transparent;
    border-bottom: 0 solid transparent;
  }
  .figure:before {
    display: block;
    content: '';
    position: absolute;
    z-index: 99;
    width: 0;
    height: 0;
    top: -194px;
    left: -90px;
    margin: auto;
    border-top: 180px solid #a40000;
    border-right: 90px solid transparent;
    border-left: 90px solid transparent;
    border-bottom: 0 solid transparent;
  }
  .figure-mask {
    display: block;
    position: absolute;
    z-index: 99;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-top: 200px solid transparent;
    border-right: 100px solid transparent;
    border-left: 100px solid transparent;
    border-bottom: 0 solid transparent;
  }
  .clock {
    display: block;
    position: absolute;
    z-index: 9;
    width: 720px;
    height: 128px;
    text-align: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    cursor: default;
  }
  .clock span {
    display: block;
    position: relative;
    font-size: 128px;
    line-height: 1;
  }
  .clock.is-off {
    -webkit-animation: is-off 2s linear infinite !important;
    animation: is-off 2s linear infinite !important;
  }
  .glitch:before {
    position: absolute;
    z-index: 999999;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation: bg-move 2s linear infinite;
    animation: bg-move 2s linear infinite;
    background-size: 100% 8px;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(0),
      color-stop(10%, rgba(255, 255, 255, 0.05)),
      color-stop(10%, transparent),
      color-stop(50%, transparent),
      color-stop(50%, rgba(255, 255, 255, 0.05)),
      color-stop(60%, rgba(255, 255, 255, 0.05)),
      color-stop(60%, transparent),
      to(transparent)
    );
    background-image: linear-gradient(
      0,
      rgba(255, 255, 255, 0.05) 10%,
      transparent 10%,
      transparent 50%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.05) 60%,
      transparent 60%,
      transparent
    );
  }
  .glitch .figure,
  .glitch .figure-mask {
    -webkit-transform: skewX(0deg) scaleY(1);
    transform: skewX(0deg) scaleY(1);
    -webkit-animation: tr-bag 4s linear infinite;
    animation: tr-bag 4s linear infinite;
  }
  .glitch .clock {
    -webkit-transform: skewX(0deg) scaleY(1);
    transform: skewX(0deg) scaleY(1);
    -webkit-animation: clock-bag 4s linear infinite;
    animation: clock-bag 4s linear infinite;
  }
  .glitch .clock span:before,
  .glitch .clock span:after {
    display: block;
    content: attr(data-time);
    position: absolute;
    top: 0;
    color: #fff;
    background: #1e1e1e;
    overflow: hidden;
    width: 720px;
    height: 128px;
    clip: rect(0, 900px, 0, 0);
  }
  .glitch .clock span:before {
    left: -2px;
    text-shadow: 2px 0 #00f;
    animation: c2 1s infinite linear alternate-reverse;
  }
  .glitch .clock span:after {
    left: 3px;
    text-shadow: -2px 0 #f00;
    animation: c1 2s infinite linear alternate-reverse;
  }
  @-webkit-keyframes is-off {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    58% {
      opacity: 1;
    }
    71% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    72% {
      -webkit-transform: scaleY(3) skewX(-60deg);
      transform: scaleY(3) skewX(-60deg);
    }
    73% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    80% {
      opacity: 1;
    }
    81% {
      opacity: 0;
    }
    84% {
      opacity: 0;
    }
    85% {
      opacity: 1;
    }
    91% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
      color: #fff;
    }
    92% {
      -webkit-transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
      transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
      color: #008000;
    }
    93% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
      color: #fff;
    }
  }
  @keyframes is-off {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    58% {
      opacity: 1;
    }
    71% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    72% {
      -webkit-transform: scaleY(3) skewX(-60deg);
      transform: scaleY(3) skewX(-60deg);
    }
    73% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    80% {
      opacity: 1;
    }
    81% {
      opacity: 0;
    }
    84% {
      opacity: 0;
    }
    85% {
      opacity: 1;
    }
    91% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
      color: #fff;
    }
    92% {
      -webkit-transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
      transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
      color: #008000;
    }
    93% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
      color: #fff;
    }
  }
  @-webkit-keyframes c1 {
    0% {
      clip: rect(22px, 9999px, 9px, 0);
    }
    5% {
      clip: rect(40px, 9999px, 44px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 27px, 0);
    }
    15.000000000000002% {
      clip: rect(91px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(65px, 9999px, 75px, 0);
    }
    25% {
      clip: rect(48px, 9999px, 58px, 0);
    }
    30.000000000000004% {
      clip: rect(1px, 9999px, 58px, 0);
    }
    35.00000000000001% {
      clip: rect(68px, 9999px, 9px, 0);
    }
    40% {
      clip: rect(27px, 9999px, 22px, 0);
    }
    45% {
      clip: rect(26px, 9999px, 89px, 0);
    }
    50% {
      clip: rect(85px, 9999px, 55px, 0);
    }
    55% {
      clip: rect(98px, 9999px, 47px, 0);
    }
    60.00000000000001% {
      clip: rect(31px, 9999px, 49px, 0);
    }
    65% {
      clip: rect(36px, 9999px, 70px, 0);
    }
    70.00000000000001% {
      clip: rect(73px, 9999px, 72px, 0);
    }
    75% {
      clip: rect(55px, 9999px, 87px, 0);
    }
    80% {
      clip: rect(99px, 9999px, 21px, 0);
    }
    85% {
      clip: rect(82px, 9999px, 49px, 0);
    }
    90% {
      clip: rect(14px, 9999px, 49px, 0);
    }
    95% {
      clip: rect(3px, 9999px, 2px, 0);
    }
    100% {
      clip: rect(61px, 9999px, 14px, 0);
    }
  }
  @keyframes c1 {
    0% {
      clip: rect(22px, 9999px, 9px, 0);
    }
    5% {
      clip: rect(40px, 9999px, 44px, 0);
    }
    10% {
      clip: rect(31px, 9999px, 27px, 0);
    }
    15.000000000000002% {
      clip: rect(91px, 9999px, 53px, 0);
    }
    20% {
      clip: rect(65px, 9999px, 75px, 0);
    }
    25% {
      clip: rect(48px, 9999px, 58px, 0);
    }
    30.000000000000004% {
      clip: rect(1px, 9999px, 58px, 0);
    }
    35.00000000000001% {
      clip: rect(68px, 9999px, 9px, 0);
    }
    40% {
      clip: rect(27px, 9999px, 22px, 0);
    }
    45% {
      clip: rect(26px, 9999px, 89px, 0);
    }
    50% {
      clip: rect(85px, 9999px, 55px, 0);
    }
    55% {
      clip: rect(98px, 9999px, 47px, 0);
    }
    60.00000000000001% {
      clip: rect(31px, 9999px, 49px, 0);
    }
    65% {
      clip: rect(36px, 9999px, 70px, 0);
    }
    70.00000000000001% {
      clip: rect(73px, 9999px, 72px, 0);
    }
    75% {
      clip: rect(55px, 9999px, 87px, 0);
    }
    80% {
      clip: rect(99px, 9999px, 21px, 0);
    }
    85% {
      clip: rect(82px, 9999px, 49px, 0);
    }
    90% {
      clip: rect(14px, 9999px, 49px, 0);
    }
    95% {
      clip: rect(3px, 9999px, 2px, 0);
    }
    100% {
      clip: rect(61px, 9999px, 14px, 0);
    }
  }
  @-webkit-keyframes c2 {
    0% {
      clip: rect(19px, 9999px, 10px, 0);
    }
    5% {
      clip: rect(10px, 9999px, 65px, 0);
    }
    10% {
      clip: rect(66px, 9999px, 66px, 0);
    }
    15.000000000000002% {
      clip: rect(8px, 9999px, 42px, 0);
    }
    20% {
      clip: rect(10px, 9999px, 16px, 0);
    }
    25% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    30.000000000000004% {
      clip: rect(32px, 9999px, 79px, 0);
    }
    35.00000000000001% {
      clip: rect(5px, 9999px, 100px, 0);
    }
    40% {
      clip: rect(76px, 9999px, 51px, 0);
    }
    45% {
      clip: rect(62px, 9999px, 80px, 0);
    }
    50% {
      clip: rect(62px, 9999px, 82px, 0);
    }
    55% {
      clip: rect(90px, 9999px, 93px, 0);
    }
    60.00000000000001% {
      clip: rect(41px, 9999px, 63px, 0);
    }
    65% {
      clip: rect(12px, 9999px, 2px, 0);
    }
    70.00000000000001% {
      clip: rect(74px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(31px, 9999px, 11px, 0);
    }
    80% {
      clip: rect(92px, 9999px, 8px, 0);
    }
    85% {
      clip: rect(5px, 9999px, 51px, 0);
    }
    90% {
      clip: rect(87px, 9999px, 70px, 0);
    }
    95% {
      clip: rect(58px, 9999px, 41px, 0);
    }
    100% {
      clip: rect(9px, 9999px, 91px, 0);
    }
    23% {
      -webkit-transform: scaleX(0.8);
      transform: scaleX(0.8);
    }
  }
  @keyframes c2 {
    0% {
      clip: rect(19px, 9999px, 10px, 0);
    }
    5% {
      clip: rect(10px, 9999px, 65px, 0);
    }
    10% {
      clip: rect(66px, 9999px, 66px, 0);
    }
    15.000000000000002% {
      clip: rect(8px, 9999px, 42px, 0);
    }
    20% {
      clip: rect(10px, 9999px, 16px, 0);
    }
    25% {
      clip: rect(18px, 9999px, 50px, 0);
    }
    30.000000000000004% {
      clip: rect(32px, 9999px, 79px, 0);
    }
    35.00000000000001% {
      clip: rect(5px, 9999px, 100px, 0);
    }
    40% {
      clip: rect(76px, 9999px, 51px, 0);
    }
    45% {
      clip: rect(62px, 9999px, 80px, 0);
    }
    50% {
      clip: rect(62px, 9999px, 82px, 0);
    }
    55% {
      clip: rect(90px, 9999px, 93px, 0);
    }
    60.00000000000001% {
      clip: rect(41px, 9999px, 63px, 0);
    }
    65% {
      clip: rect(12px, 9999px, 2px, 0);
    }
    70.00000000000001% {
      clip: rect(74px, 9999px, 17px, 0);
    }
    75% {
      clip: rect(31px, 9999px, 11px, 0);
    }
    80% {
      clip: rect(92px, 9999px, 8px, 0);
    }
    85% {
      clip: rect(5px, 9999px, 51px, 0);
    }
    90% {
      clip: rect(87px, 9999px, 70px, 0);
    }
    95% {
      clip: rect(58px, 9999px, 41px, 0);
    }
    100% {
      clip: rect(9px, 9999px, 91px, 0);
    }
    23% {
      -webkit-transform: scaleX(0.8);
      transform: scaleX(0.8);
    }
  }
  @-webkit-keyframes clock-bag {
    0% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    2% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    4% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    6% {
      -webkit-transform: translate(1px, 4px);
      transform: translate(1px, 4px);
    }
    8% {
      -webkit-transform: translate(5px, 1px);
      transform: translate(5px, 1px);
    }
    10% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    12% {
      -webkit-transform: translate(2px, 3px);
      transform: translate(2px, 3px);
    }
    14.000000000000002% {
      -webkit-transform: translate(2px, 3px);
      transform: translate(2px, 3px);
    }
    16% {
      -webkit-transform: translate(4px, 3px);
      transform: translate(4px, 3px);
    }
    18% {
      -webkit-transform: translate(5px, 3px);
      transform: translate(5px, 3px);
    }
    20% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    22% {
      -webkit-transform: translate(2px, 1px);
      transform: translate(2px, 1px);
    }
    24% {
      -webkit-transform: translate(1px, 3px);
      transform: translate(1px, 3px);
    }
    26% {
      -webkit-transform: translate(1px, 3px);
      transform: translate(1px, 3px);
    }
    28.000000000000004% {
      -webkit-transform: translate(4px, 2px);
      transform: translate(4px, 2px);
    }
    30% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    32% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    34% {
      -webkit-transform: translate(3px, 5px);
      transform: translate(3px, 5px);
    }
    36% {
      -webkit-transform: translate(4px, 5px);
      transform: translate(4px, 5px);
    }
    38% {
      -webkit-transform: translate(1px, 1px);
      transform: translate(1px, 1px);
    }
    40% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    42% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    44% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    46.00000000000001% {
      -webkit-transform: translate(1px, 3px);
      transform: translate(1px, 3px);
    }
    48% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    50% {
      -webkit-transform: translate(5px, 2px);
      transform: translate(5px, 2px);
    }
    52% {
      -webkit-transform: translate(5px, 4px);
      transform: translate(5px, 4px);
    }
    54% {
      -webkit-transform: translate(3px, 1px);
      transform: translate(3px, 1px);
    }
    56.00000000000001% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    58% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    60% {
      -webkit-transform: translate(3px, 4px);
      transform: translate(3px, 4px);
    }
    62% {
      -webkit-transform: translate(2px, 1px);
      transform: translate(2px, 1px);
    }
    64% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    66% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    68% {
      -webkit-transform: translate(4px, 3px);
      transform: translate(4px, 3px);
    }
    70.00000000000001% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    72% {
      -webkit-transform: translate(5px, 2px);
      transform: translate(5px, 2px);
    }
    74% {
      -webkit-transform: translate(5px, 2px);
      transform: translate(5px, 2px);
    }
    76% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    78% {
      -webkit-transform: translate(4px, 5px);
      transform: translate(4px, 5px);
    }
    80% {
      -webkit-transform: translate(1px, 2px);
      transform: translate(1px, 2px);
    }
    82.00000000000001% {
      -webkit-transform: translate(1px, 2px);
      transform: translate(1px, 2px);
    }
    84% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    86% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    88% {
      -webkit-transform: translate(4px, 2px);
      transform: translate(4px, 2px);
    }
    90% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    92.00000000000001% {
      -webkit-transform: translate(3px, 5px);
      transform: translate(3px, 5px);
    }
    94% {
      -webkit-transform: translate(5px, 2px);
      transform: translate(5px, 2px);
    }
    96% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    98% {
      -webkit-transform: translate(3px, 3px);
      transform: translate(3px, 3px);
    }
    100% {
      -webkit-transform: translate(3px, 4px);
      transform: translate(3px, 4px);
    }
    1% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    1.5% {
      -webkit-transform: scaleY(3) skewX(-60deg);
      transform: scaleY(3) skewX(-60deg);
    }
    2% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    51% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
    }
    52% {
      -webkit-transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
      transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
    }
    53% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
    }
  }
  @keyframes clock-bag {
    0% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    2% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    4% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    6% {
      -webkit-transform: translate(1px, 4px);
      transform: translate(1px, 4px);
    }
    8% {
      -webkit-transform: translate(5px, 1px);
      transform: translate(5px, 1px);
    }
    10% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    12% {
      -webkit-transform: translate(2px, 3px);
      transform: translate(2px, 3px);
    }
    14.000000000000002% {
      -webkit-transform: translate(2px, 3px);
      transform: translate(2px, 3px);
    }
    16% {
      -webkit-transform: translate(4px, 3px);
      transform: translate(4px, 3px);
    }
    18% {
      -webkit-transform: translate(5px, 3px);
      transform: translate(5px, 3px);
    }
    20% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    22% {
      -webkit-transform: translate(2px, 1px);
      transform: translate(2px, 1px);
    }
    24% {
      -webkit-transform: translate(1px, 3px);
      transform: translate(1px, 3px);
    }
    26% {
      -webkit-transform: translate(1px, 3px);
      transform: translate(1px, 3px);
    }
    28.000000000000004% {
      -webkit-transform: translate(4px, 2px);
      transform: translate(4px, 2px);
    }
    30% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    32% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    34% {
      -webkit-transform: translate(3px, 5px);
      transform: translate(3px, 5px);
    }
    36% {
      -webkit-transform: translate(4px, 5px);
      transform: translate(4px, 5px);
    }
    38% {
      -webkit-transform: translate(1px, 1px);
      transform: translate(1px, 1px);
    }
    40% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    42% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    44% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    46.00000000000001% {
      -webkit-transform: translate(1px, 3px);
      transform: translate(1px, 3px);
    }
    48% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    50% {
      -webkit-transform: translate(5px, 2px);
      transform: translate(5px, 2px);
    }
    52% {
      -webkit-transform: translate(5px, 4px);
      transform: translate(5px, 4px);
    }
    54% {
      -webkit-transform: translate(3px, 1px);
      transform: translate(3px, 1px);
    }
    56.00000000000001% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    58% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    60% {
      -webkit-transform: translate(3px, 4px);
      transform: translate(3px, 4px);
    }
    62% {
      -webkit-transform: translate(2px, 1px);
      transform: translate(2px, 1px);
    }
    64% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    66% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    68% {
      -webkit-transform: translate(4px, 3px);
      transform: translate(4px, 3px);
    }
    70.00000000000001% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    72% {
      -webkit-transform: translate(5px, 2px);
      transform: translate(5px, 2px);
    }
    74% {
      -webkit-transform: translate(5px, 2px);
      transform: translate(5px, 2px);
    }
    76% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    78% {
      -webkit-transform: translate(4px, 5px);
      transform: translate(4px, 5px);
    }
    80% {
      -webkit-transform: translate(1px, 2px);
      transform: translate(1px, 2px);
    }
    82.00000000000001% {
      -webkit-transform: translate(1px, 2px);
      transform: translate(1px, 2px);
    }
    84% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    86% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    88% {
      -webkit-transform: translate(4px, 2px);
      transform: translate(4px, 2px);
    }
    90% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    92.00000000000001% {
      -webkit-transform: translate(3px, 5px);
      transform: translate(3px, 5px);
    }
    94% {
      -webkit-transform: translate(5px, 2px);
      transform: translate(5px, 2px);
    }
    96% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    98% {
      -webkit-transform: translate(3px, 3px);
      transform: translate(3px, 3px);
    }
    100% {
      -webkit-transform: translate(3px, 4px);
      transform: translate(3px, 4px);
    }
    1% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    1.5% {
      -webkit-transform: scaleY(3) skewX(-60deg);
      transform: scaleY(3) skewX(-60deg);
    }
    2% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    51% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
    }
    52% {
      -webkit-transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
      transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
    }
    53% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
    }
  }
  @-webkit-keyframes tr-bag {
    0% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    2% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    4% {
      -webkit-transform: translate(2px, 1px);
      transform: translate(2px, 1px);
    }
    6% {
      -webkit-transform: translate(4px, 2px);
      transform: translate(4px, 2px);
    }
    8% {
      -webkit-transform: translate(3px, 5px);
      transform: translate(3px, 5px);
    }
    10% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    12% {
      -webkit-transform: translate(1px, 4px);
      transform: translate(1px, 4px);
    }
    14.000000000000002% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    16% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    18% {
      -webkit-transform: translate(3px, 1px);
      transform: translate(3px, 1px);
    }
    20% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    22% {
      -webkit-transform: translate(2px, 3px);
      transform: translate(2px, 3px);
    }
    24% {
      -webkit-transform: translate(1px, 1px);
      transform: translate(1px, 1px);
    }
    26% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    28.000000000000004% {
      -webkit-transform: translate(1px, 4px);
      transform: translate(1px, 4px);
    }
    30% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    32% {
      -webkit-transform: translate(5px, 3px);
      transform: translate(5px, 3px);
    }
    34% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    36% {
      -webkit-transform: translate(4px, 5px);
      transform: translate(4px, 5px);
    }
    38% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    40% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    42% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    44% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    46.00000000000001% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    48% {
      -webkit-transform: translate(1px, 4px);
      transform: translate(1px, 4px);
    }
    50% {
      -webkit-transform: translate(4px, 5px);
      transform: translate(4px, 5px);
    }
    52% {
      -webkit-transform: translate(1px, 5px);
      transform: translate(1px, 5px);
    }
    54% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    56.00000000000001% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    58% {
      -webkit-transform: translate(4px, 2px);
      transform: translate(4px, 2px);
    }
    60% {
      -webkit-transform: translate(3px, 4px);
      transform: translate(3px, 4px);
    }
    62% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    64% {
      -webkit-transform: translate(2px, 3px);
      transform: translate(2px, 3px);
    }
    66% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    68% {
      -webkit-transform: translate(1px, 4px);
      transform: translate(1px, 4px);
    }
    70.00000000000001% {
      -webkit-transform: translate(5px, 1px);
      transform: translate(5px, 1px);
    }
    72% {
      -webkit-transform: translate(3px, 5px);
      transform: translate(3px, 5px);
    }
    74% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    76% {
      -webkit-transform: translate(5px, 2px);
      transform: translate(5px, 2px);
    }
    78% {
      -webkit-transform: translate(4px, 2px);
      transform: translate(4px, 2px);
    }
    80% {
      -webkit-transform: translate(3px, 3px);
      transform: translate(3px, 3px);
    }
    82.00000000000001% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    84% {
      -webkit-transform: translate(3px, 3px);
      transform: translate(3px, 3px);
    }
    86% {
      -webkit-transform: translate(1px, 3px);
      transform: translate(1px, 3px);
    }
    88% {
      -webkit-transform: translate(1px, 2px);
      transform: translate(1px, 2px);
    }
    90% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    92.00000000000001% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    94% {
      -webkit-transform: translate(2px, 1px);
      transform: translate(2px, 1px);
    }
    96% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    98% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    100% {
      -webkit-transform: translate(3px, 3px);
      transform: translate(3px, 3px);
    }
    1% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    1.5% {
      -webkit-transform: scaleY(3) skewX(-60deg);
      transform: scaleY(3) skewX(-60deg);
    }
    2% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    51% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
    }
    52% {
      -webkit-transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
      transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
    }
    53% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
    }
  }
  @keyframes tr-bag {
    0% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    2% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    4% {
      -webkit-transform: translate(2px, 1px);
      transform: translate(2px, 1px);
    }
    6% {
      -webkit-transform: translate(4px, 2px);
      transform: translate(4px, 2px);
    }
    8% {
      -webkit-transform: translate(3px, 5px);
      transform: translate(3px, 5px);
    }
    10% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    12% {
      -webkit-transform: translate(1px, 4px);
      transform: translate(1px, 4px);
    }
    14.000000000000002% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    16% {
      -webkit-transform: translate(4px, 1px);
      transform: translate(4px, 1px);
    }
    18% {
      -webkit-transform: translate(3px, 1px);
      transform: translate(3px, 1px);
    }
    20% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    22% {
      -webkit-transform: translate(2px, 3px);
      transform: translate(2px, 3px);
    }
    24% {
      -webkit-transform: translate(1px, 1px);
      transform: translate(1px, 1px);
    }
    26% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    28.000000000000004% {
      -webkit-transform: translate(1px, 4px);
      transform: translate(1px, 4px);
    }
    30% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    32% {
      -webkit-transform: translate(5px, 3px);
      transform: translate(5px, 3px);
    }
    34% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    36% {
      -webkit-transform: translate(4px, 5px);
      transform: translate(4px, 5px);
    }
    38% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    40% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    42% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    44% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    46.00000000000001% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    48% {
      -webkit-transform: translate(1px, 4px);
      transform: translate(1px, 4px);
    }
    50% {
      -webkit-transform: translate(4px, 5px);
      transform: translate(4px, 5px);
    }
    52% {
      -webkit-transform: translate(1px, 5px);
      transform: translate(1px, 5px);
    }
    54% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    56.00000000000001% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    58% {
      -webkit-transform: translate(4px, 2px);
      transform: translate(4px, 2px);
    }
    60% {
      -webkit-transform: translate(3px, 4px);
      transform: translate(3px, 4px);
    }
    62% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    64% {
      -webkit-transform: translate(2px, 3px);
      transform: translate(2px, 3px);
    }
    66% {
      -webkit-transform: translate(4px, 4px);
      transform: translate(4px, 4px);
    }
    68% {
      -webkit-transform: translate(1px, 4px);
      transform: translate(1px, 4px);
    }
    70.00000000000001% {
      -webkit-transform: translate(5px, 1px);
      transform: translate(5px, 1px);
    }
    72% {
      -webkit-transform: translate(3px, 5px);
      transform: translate(3px, 5px);
    }
    74% {
      -webkit-transform: translate(2px, 4px);
      transform: translate(2px, 4px);
    }
    76% {
      -webkit-transform: translate(5px, 2px);
      transform: translate(5px, 2px);
    }
    78% {
      -webkit-transform: translate(4px, 2px);
      transform: translate(4px, 2px);
    }
    80% {
      -webkit-transform: translate(3px, 3px);
      transform: translate(3px, 3px);
    }
    82.00000000000001% {
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px);
    }
    84% {
      -webkit-transform: translate(3px, 3px);
      transform: translate(3px, 3px);
    }
    86% {
      -webkit-transform: translate(1px, 3px);
      transform: translate(1px, 3px);
    }
    88% {
      -webkit-transform: translate(1px, 2px);
      transform: translate(1px, 2px);
    }
    90% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    92.00000000000001% {
      -webkit-transform: translate(3px, 2px);
      transform: translate(3px, 2px);
    }
    94% {
      -webkit-transform: translate(2px, 1px);
      transform: translate(2px, 1px);
    }
    96% {
      -webkit-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
    98% {
      -webkit-transform: translate(2px, 5px);
      transform: translate(2px, 5px);
    }
    100% {
      -webkit-transform: translate(3px, 3px);
      transform: translate(3px, 3px);
    }
    1% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    1.5% {
      -webkit-transform: scaleY(3) skewX(-60deg);
      transform: scaleY(3) skewX(-60deg);
    }
    2% {
      -webkit-transform: scaleY(1) skewX(0deg);
      transform: scaleY(1) skewX(0deg);
    }
    51% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
    }
    52% {
      -webkit-transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
      transform: scaleX(1.5) scaleY(0.2) skewX(80deg);
    }
    53% {
      -webkit-transform: scaleX(1) scaleY(1) skewX(0deg);
      transform: scaleX(1) scaleY(1) skewX(0deg);
    }
  }
  @-webkit-keyframes bg-move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 0 -32px;
    }
  }
  @keyframes bg-move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 0 -32px;
    }
  }
`

const CountDown = ({ startDate }: { startDate: number }) => {
  const clock = useRef(null)
  const [realTime, setRealTime] = useState('#BeReady')
  const clockShow = true

  useEffect(() => {
    setInterval(() => {
      // const restTime = (startDate - Math.floor(new Date().getTime() / 1000)) * 1000
      var _realTime = clockShow ? timestampToCountDown() : '#BeReady'
      setRealTime(_realTime)
    }, 1000)
  })

  return (
    <>
      <Canvas>
        <BodyHeader className={`logo ${clockShow ? 'animate' : ''}`} />
        <div className="screen glitch">
          <div ref={clock} className="clock is-off">
            <span className="time" data-time={realTime}>
              {realTime}
            </span>
          </div>
          <div className={clockShow ? 'figure' : ''}></div>
          <div className={clockShow ? 'figure-mask' : ''}></div>
        </div>
      </Canvas>
      <BodyFooter style={{ zIndex: '999', fontSize: '18px' }} />
    </>
  )
}

export default CountDown
