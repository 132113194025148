import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import 'rc-slider/assets/index.css'

import Frame from '../../components/Frame'
import Winners from './components/Winners'
import useTopPlayers from '../../hooks/useTopPlayers'
import WagerVolume from './components/WagerVolume'
import AirdropCard from './components/AirdropCard'

export const ColWithMobileMargin = styled(Col)`
  padding: 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 24px;
  `};
`

export const SuccessText = styled('div')`
  color: ${({ theme }) => theme.colors.green1};
  font-family: GothamRoundedBold;
`

export default function Leaderboard() {
  const topPlayers = useTopPlayers()

  return (
    <Frame maxWidth={980}>
      <Container>
        <Row xs={1} md={2} style={{justifyContent: 'space-around', marginBottom: '24px' }}>
          <ColWithMobileMargin xs={12} md={4} >
            <AirdropCard/>
          </ColWithMobileMargin>
          <ColWithMobileMargin xs={12} md={4}>
            <WagerVolume topPlayers={topPlayers} />
          </ColWithMobileMargin>
        </Row>
        <ColWithMobileMargin xs={12} md={8} style={{ margin: 'auto' }}>
          <div style={{ marginTop: 40 }}>
            <Winners topPlayers={topPlayers}/>
          </div>
        </ColWithMobileMargin>
      </Container>
    </Frame>
  )
}
