import BN from 'bignumber.js'
import { BIGNUMBER_FMT } from '../constants'

export const getBalanceNumber = (balance: BN, decimals = 18, format = 2) => {
  const displayBalance = balance.div(new BN(10).pow(decimals))
  return displayBalance.toFormat(format, BIGNUMBER_FMT)
}

export const getDisplayBalance = (balance: BN, decimals = 18) => {
  const displayBalance = balance.div(new BN(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(5)
  } else {
    return displayBalance.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const getFullDisplayBalance = (balance: BN, decimals = 18) => {
  return balance.div(new BN(10).pow(decimals)).toFixed()
}

// export const getDisplayMoney = (num: BN, decimals = 18): string => {
//   const displayBalance = num.div(new BN(10).pow(decimals))
//   if (displayBalance.gte(1000000000)) {
//     return (
//       displayBalance
//         .div(1000000000)
//         .toFixed(1)
//         .replace(/\.0$/, '') + 'B'
//     )
//   }
//   if (displayBalance.gte(1000000)) {
//     return (
//       displayBalance
//         .div(1000000)
//         .toFixed(1)
//         .replace(/\.0$/, '') + 'M'
//     )
//   }
//   if (displayBalance.gte(1000)) {
//     return (
//       displayBalance
//         .div(1000)
//         .toFixed(1)
//         .replace(/\.0$/, '') + 'K'
//     )
//   }
//   return displayBalance.toString()
// }
